import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';
import { VendorSummary, VendorsRepository } from '@eceos/domain';
import { SettingsService } from '../../../core/settings.service';

@Component({
  selector: 'app-default-sale-vendor',
  templateUrl: './default-sale-vendor.component.html',
  styleUrls: ['./default-sale-vendor.component.scss']
})
export class DefaultSaleVendorComponent implements OnInit {

  readonly vendors: Observable<VendorSummary[]>;

  constructor(
    private repository: VendorsRepository,
    private settingsRepository: SettingsService
  ) {
    this.vendors = repository.list();
  }

  ngOnInit() {}

  get defaultVendor(): VendorSummary {
    return this.settingsRepository.defaultVendor;
  }

  onVendorSelected(event: MatSelectChange) {
    this.settingsRepository.defaultVendor = event.value;
  }

  compareById(obj1: VendorSummary, obj2: VendorSummary) {
    return obj1 === obj2 || (obj1 && obj2 && obj1.id === obj2.id);
  }
}
