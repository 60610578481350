import { Injectable } from "@angular/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { map } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class EceosLayoutBreakpointsService {

    isMobile$ = this.breakpointObserver
        .observe(['(max-width: 425px)'])
        .pipe(
            map((state: BreakpointState) => state.matches)
        );

    isTablet$ = this.breakpointObserver
        .observe(['(max-width: 768px)'])
        .pipe(
            map((state: BreakpointState) => state.matches)
        );

    isSmallPc$ = this.breakpointObserver
        .observe(['(max-width: 1024px)'])
        .pipe(
            map((state: BreakpointState) => state.matches)
        );

    isMediumPc$ = this.breakpointObserver
        .observe(['(max-width: 1366px)'])
        .pipe(
            map((state: BreakpointState) => state.matches)
        );

    isLargePc$ = this.breakpointObserver
        .observe(['(max-width: 1920px;)'])
        .pipe(
            map((state: BreakpointState) => state.matches)
        );

    isPortrait$ = this.breakpointObserver
        .observe(['(orientation: portrait)'])
        .pipe(
            map((state: BreakpointState) => state.matches)
        );

    isLandscape$ = this.breakpointObserver
        .observe(['(orientation: landscape)'])
        .pipe(
            map((state: BreakpointState) => state.matches)
        );

    constructor(private breakpointObserver: BreakpointObserver) { }

}

