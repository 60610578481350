import { JsonEntity, SgvId, SgvJson } from "@eceos/arch";
import { ProductSummary } from "../../products/product-summary";
import { ServiceSummary } from "../../services/service-summary";


export abstract class OperatableTreeNode implements JsonEntity {
    constructor(
        readonly id: string = SgvId.gen(),
        public name: string = '',
        public hasChild = true,
    ) { }

    toJson() {
        throw Error('Não é possível criar ou atualizar OperatableTreeNode');
    }

    static fromJson(json: any): OperatableTreeNode {
        if (!json || !json.type) {
            return null;
        }
        switch (json.type) {
            case 'nodeProduct':
                return NodeProduct.fromJson(json);
            case 'nodeProductCategory':
                return NodeProductCategory.fromJson(json)
            case 'nodeService':
                return NodeService.fromJson(json)
            case 'nodeServiceCategory':
                return NodeServiceCategory.fromJson(json)
            case 'nodeParent':
                return NodeParent.fromJson(json)
            case 'typeProduct':
                return NodeTypeProduct.fromJson(json)
            case 'typeService':
                return NodeTypeService.fromJson(json)
            default:
                throw new Error('OperatableTreeNode type not mapped')
        }
    }
}

export class NodeProduct extends OperatableTreeNode {
    constructor(
        readonly id: string = SgvId.gen(),
        public name: string = '',
        public hasChild = false,
        public productSummary: ProductSummary = null
    ) {
        super(id, name, hasChild)
    }

    static fromJson(json: any): NodeProduct {
        return json
            ? SgvJson.from.simple(json, NodeProduct, {
                productSummary: json.productSummary ? ProductSummary.fromJson(json.productSummary) : null
            }) : null
    }
}

export class NodeProductCategory extends OperatableTreeNode {
    constructor(
        readonly id: string = SgvId.gen(),
        public name: string = '',
        public hasChild = false,
    ) {
        super(id, name, hasChild)
    }

    static fromJson(json: any): NodeProductCategory {
        return json
            ? SgvJson.from.simple(json, NodeProductCategory) : null
    }

}

export class NodeService extends OperatableTreeNode {
    constructor(
        readonly id: string = SgvId.gen(),
        public name: string = '',
        public hasChild = false,
        public serviceSummary: ServiceSummary = null
    ) {
        super(id, name, hasChild)
    }

    static fromJson(json: any): NodeService {
        return json
            ? SgvJson.from.simple(json, NodeService, {
                serviceSummary: json.serviceSummary ? ServiceSummary.fromJson(json.serviceSummary) : null
            }) : null
    }
}

export class NodeServiceCategory extends OperatableTreeNode {
    constructor(
        readonly id: string = SgvId.gen(),
        public name: string = '',
        public hasChild = true,
    ) {
        super(id, name, hasChild)
    }

    static fromJson(json: any): NodeServiceCategory {
        return json
            ? SgvJson.from.simple(json, NodeServiceCategory) : null
    }
}

export class NodeParent extends OperatableTreeNode {
    constructor(
        readonly id: string = SgvId.gen(),
        public name: string = '',
        public hasChild = true,
        public hasASingleCategoryAsChild = false,
        public parent: NodeParent = null
    ) {
        super(id, name, hasChild)
    }

    static fromJson(json: any): NodeParent {
        return json
            ? SgvJson.from.simple(json, NodeParent, {
                parent: json.parent ? NodeParent.fromJson(json.parent) : null
            }) : null
    }
}

export class NodeTypeProduct extends OperatableTreeNode {
    constructor(
        readonly id: string = SgvId.gen(),
        public name: string = '',
        public hasChild = true,
    ) {
        super(id, name, hasChild)
    }

    static fromJson(json: any): NodeTypeProduct {
        return json
            ? SgvJson.from.simple(json, NodeTypeProduct) : null
    }
}

export class NodeTypeService extends OperatableTreeNode {
    constructor(
        readonly id: string = SgvId.gen(),
        public name: string = '',
        public hasChild = true,
    ) {
        super(id, name, hasChild)
    }

    static fromJson(json: any): NodeTypeService {
        return json
            ? SgvJson.from.simple(json, NodeTypeService) : null
    }
}