import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@eceos/arch';
import { SyncGuard } from '../../core/sync/sync.guard';
import { SaleDeliveryComponent } from './sale-delivery/sale-delivery.component';
import { SaleDetailsComponent } from './sale-details/sale-details.component';
import { SaleListComponent } from './sale-list/sale-list.component';
import { SalePaymentsComponent } from './sale-payments/sale-payments.component';
import { SalePostFinishComponent } from './sale-post-finish/sale-post-finish.component';

export const routes: Routes = [
  {
    path: 'sales',
    component: SaleListComponent,
    data: { title: 'e-Ceos PDV - Lista de Vendas' },
    canActivate: [AuthGuard]
  },
  { path: 'sales/:id', redirectTo: 'sales/:id/details', pathMatch: 'full' },
  {
    path: 'sales/:id/details',
    component: SaleDetailsComponent,
    data: { title: 'e-Ceos PDV - Venda - Produtos' },
    canActivate: [AuthGuard],
    canDeactivate: [SyncGuard]
  },
  {
    path: 'sales/:id/payments',
    component: SalePaymentsComponent,
    data: { title: 'e-Ceos PDV - Venda - Pagamento' }, 
    canActivate: [AuthGuard]
  },
  {
    path: 'sales/:id/postFinish',
    component: SalePostFinishComponent,
    data: { title: 'e-Ceos PDV - Venda Finalizada' },
    canActivate: [AuthGuard]
  },
  {
    path: 'sales/:id/delivery',
    component: SaleDeliveryComponent,
    data: { title: 'e-Ceos PDV - Entrega' },
    canActivate: [AuthGuard]
  }
];

export const SalesRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
