import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService, ApiPath, InterceptorConfig, RequestMetadata } from '@eceos/arch';
import { Sale, RemotePrinterSummary } from '@eceos/domain';

@Injectable({
  providedIn: 'root'
})
export class FinancialMovementsRepository {
  private api: ApiPath;

  metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  constructor(api: ApiService) {
    this.api = api.root.path('financial/movements');
  }

  getReportPDFFromSale(sale: Sale): Observable<Blob> {
    return this.api
      .path('fromSale')
      .path(sale.id)
      .path('report.pdf')
      .getBlob({ metadata: this.metadata });
  }

  printReportPDFFromSale(sale: Sale, printer: RemotePrinterSummary): Observable<Response> {
    const form = new URLSearchParams();
    form.set('printerId', printer.id);
    return this.api
      .path('fromSale')
      .path(sale.id)
      .path('report.pdf')
      .path('printTo')
      .postForm(form, { metadata: this.metadata });
  }
}
