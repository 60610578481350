import { Injectable } from "@angular/core";
import { Actives, ApiService, Filter, InterceptorConfig, PageData, ReadonlyRepositoryImpl, RepositoryImpl, RequestMetadata, RequestMetadataUtils, SortOrder, UrlSearchParamsBuilder } from "@eceos/arch";
import { retryExceptOnClientError } from "@eceos/common-utils";
import { map, Observable, retryWhen } from "rxjs";
import { OperatableTreeNode } from "./operatable-tree-node";

@Injectable({ providedIn: 'root' })
export class OperatableTreeNodeRepository extends RepositoryImpl<OperatableTreeNode, OperatableTreeNode> {
    constructor(api: ApiService) {
        super(api.root.path('/operatableTree'), OperatableTreeNode, OperatableTreeNode)
    }

    openNode(
        pageData: PageData,
        query: string = '',
        id: string = '',
        filters: Filter[] = [],
        sortOrders: SortOrder[] = [],
        actives = Actives.TRUE,
        metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
    ): Observable<OperatableTreeNode[]> {
        const search = new UrlSearchParamsBuilder()
            .page(pageData)
            .query(query)
            .filters(filters)
            .sortOrders(sortOrders)
            .actives(actives)
            .build();
        return this.api
            .path(id)
            .getJson<any[]>({
                params: search,
                metadata: RequestMetadataUtils.merge(metadata, this.metadata)
            })
            .pipe(
                map((r) => r.map((j: any) => this.type.fromJson(j))),
                retryWhen(retryExceptOnClientError())
            );
    }

}