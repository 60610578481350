import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";


export class ServiceSummary implements DomainEntity {
    constructor(
        public readonly id: string = SgvId.gen(),
        public readonly name: string = '',
        public readonly isActive: boolean = true
    ){ }
    
    toJson(): any {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): ServiceSummary {
        return json
        ? SgvJson.from.simple(json, ServiceSummary) : null
    }
}