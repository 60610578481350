import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { GuestCardsListComponent } from './guest-cards-list.component';
import { GuestCardsRoutes } from './guest-cards.routes';

@NgModule({
  declarations: [GuestCardsListComponent],
  imports: [CommonModule, SharedModule, GuestCardsRoutes]
})
export class GuestCardsModule {}
