import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[maxValue]'
})
export class MaxValueDirective {
  @Input() maxValue = 0;
  constructor(private elementRef: ElementRef) {
    if (elementRef.nativeElement.type && elementRef.nativeElement.type !== 'number') {
      throw new Error('Directive maxValue should be used only on number input!');
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(e: Event) {
    setTimeout(() => {
      this.validate(e);
    }, 1);
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(e: KeyboardEvent) {
    if (!isNaN(+e.key)) {
      this.validate(e);
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    if (!isNaN(+e.key)) {
      this.validate(e);
    }
  }

  private validate(e: Event) {
    if (this.maxValue !== 0 && this.maxValue < this.value) {
      this.value = this.maxValue;
      e.preventDefault();
    }
  }

  get value(): number {
    return this.elementRef.nativeElement.value;
  }

  set value(value: number) {
    this.elementRef.nativeElement.value = value;
  }
}
