import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SaleDeliveryModule } from './sale-delivery/sale-delivery.module';
import { SaleDetailsModule } from './sale-details/sale-details.module';
import { SaleListComponent } from './sale-list/sale-list.component';
import { SalePaymentsModule } from './sale-payments/sale-payments.module';
import { SalePostFinishModule } from './sale-post-finish/sale-post-finish.module';
import { SalesRoutes } from './sales.routes';

@NgModule({
  declarations: [SaleListComponent],
  imports: [
    CommonModule,
    SharedModule,
    SalesRoutes,
    SaleDetailsModule,
    SalePaymentsModule,
    SalePostFinishModule,
    SaleDeliveryModule
  ]
})
export class SalesModule {}
