import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HotkeySet, Hotkey } from '@eceos/common-utils';

export interface SaleDetaisDialogData {
  readonly details: string;
  readonly hotkeys: HotkeySet;
}

@Component({
  selector: 'app-sale-details-observations',
  templateUrl: './sale-details-observations.component.html',
  styleUrls: ['./sale-details-observations.component.scss']
})
export class SaleDetailsObservationsComponent implements OnInit, OnDestroy {
  private hotkeys = HotkeySet.of([
    Hotkey.key('return')
      .description('Enviar Observação')
      .do(() => this.send()),
    Hotkey.key('esc')
      .key('f9')
      .description('Fechar Observação')
      .do(() => this.cancel())
      .asConsumed()
  ]);

  value: string;

  constructor(
    public dialogRef: MatDialogRef<SaleDetailsObservationsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: SaleDetaisDialogData
  ) {
    this.value = data.details;
  }

  ngOnInit(): void {
    console.log(this.data)
    if (this.data.hotkeys) {
      this.data.hotkeys.addSet(this.hotkeys);
    }
  }
  ngOnDestroy(): void {
    if (this.data.hotkeys) {
      this.data.hotkeys.removeSet(this.hotkeys);
    }
  }

  cancel() {
    this.dialogRef.close(true);
  }

  send() {
    this.dialogRef.close(this.value);
  }
}
