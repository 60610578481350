import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GuestCardSummary } from './guest-card';
import {
  ReadonlyRepositoryImpl,
  ApiService,
  InterceptorConfig,
  RequestMetadata,
  RequestMetadataUtils
} from '@eceos/arch';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GuestCardsRepository extends ReadonlyRepositoryImpl<
  GuestCardSummary,
  GuestCardSummary
> {
  constructor(api: ApiService) {
    super(api.root.path('guestCards'), GuestCardSummary, GuestCardSummary);
  }

  findByBarcode(
    barcode: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<GuestCardSummary> {
    return this.api
      .path('/byBarcode/')
      .path(barcode)
      .getJson({ metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map(r => this.summaryType.fromJson(r)));
  }
}
