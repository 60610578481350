import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OperatablesRepository, Product } from '@eceos/domain';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDialogComponent implements OnInit {

  public product: Product;

  id: string = '';

  constructor(
    private dialogRef: MatDialogRef<ProductDialogComponent>,
    private repository: OperatablesRepository,
  ) { }

  ngOnInit(): void {
  }

  onProductSelect() {
    if (this.id) {
      this.repository.find(this.id[0]).subscribe(operatable => this.dialogRef.close(operatable))
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  trackById(i: number, entity: any): any {
    return entity?.id;
  }

}
