<app-layout-back [hotkeys]="hotkeys">
  <section class="content">
    <mat-card class="headerCard">
      <mat-card-title> <small> Formas de Pagamento </small> </mat-card-title>
      <mat-card-content>
        <button
          mat-raised-button
          [class.selected]="sale?.isCashPaymentForm()"
          (click)="sale?.asCashPaymentForm()"
          [disabled]="!sale"
        >
          (F3) PAGAMENTO À VISTA
        </button>
        <button
          mat-raised-button
          [class.selected]="sale?.isDeferredPaymentForm()"
          (click)="sale?.asDeferredPaymentForm()"
          [disabled]="!sale"
        >
          (F4) PAGAMENTO À PRAZO
        </button>
        <ng-container *ngIf="sale?.payment">
          <app-payment-label-value
            label="Total dos Itens"
            value="{{ sale?.totalOfItems }}"
          ></app-payment-label-value>
          <app-payment-label-value
            label="Total da Entrega"
            value="{{ sale?.totalFreight }}"
            *ngIf="isWithDelivery"
          ></app-payment-label-value>
          <app-payment-addition [sale]="sale"></app-payment-addition>
          <app-payment-discount [sale]="sale"></app-payment-discount>
        </ng-container>
      </mat-card-content>
    </mat-card>

    <mat-card class="paymentCard" *ngIf="sale?.payment">
      <mat-card-content>
        <app-cash-payment
          *ngIf="sale?.isCashPaymentForm()"
          [cashPaymentForm]="sale?.payment"
          [toPay]="sale?.total"
        ></app-cash-payment>
        <app-deferred-payment
          *ngIf="sale?.isDeferredPaymentForm()"
          [deferredPaymentForm]="sale?.payment"
          [toPay]="sale?.total"
        ></app-deferred-payment>
      </mat-card-content>
    </mat-card>
  </section>

  <button mat-fab class="floatDefault" *ngIf="nextEnabled()" (click)="submitAndNext()">
    <mat-icon>done</mat-icon>
  </button>
</app-layout-back>
