import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  AUTO_CATCH_CONFIG,
  HttpErrorHandler,
  HttpErrorInterceptor,
  InactiveEnterpriseErrorHandler,
  InterceptorConfig
} from '@eceos/arch';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { CoreModule } from './core/core.module';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxGoogleAnalyticsModule.forRoot(environment.gaCode),
    NgxGoogleAnalyticsRouterModule,
    AppRoutes,
    CoreModule,
    SharedModule,
    FeaturesModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: AUTO_CATCH_CONFIG, useValue: InterceptorConfig.INTERCEPT },
    { provide: HttpErrorHandler, useClass: InactiveEnterpriseErrorHandler, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
