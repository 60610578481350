import { Component, Input, OnInit } from '@angular/core';
import { MovideskService } from '@eceos/domain';

@Component({
  selector: 'app-layout-menu',
  templateUrl: './layout-menu.component.html',
  styleUrls: ['./layout-menu.component.scss']
})
export class LayoutMenuComponent implements OnInit {
  @Input() loading = false;

  // msg: Message[] = [];

  // constructor(private enterprisesService: EnterprisesService) { }
  constructor(private movideskService: MovideskService) {}

  get showHelp() {
    return this.movideskService.isEnabled;
  }

  ngOnInit() {}
  //   this.enterprisesService.isInactive().subscribe(
  //     r => {
  //       var date: Date = new Date(r);
  //       this.msg.push({ severity: 'error', summary: 'Aviso de suspensão de contrato:', detail: 'Caro cliente, por motivo de inadimplência o seu acesso a este sistema será suspenso em ' + this.getRemainingDays(date) + ' dias. Para evitar o encerramento do seu contrato em ' + date.toLocaleDateString() + ', contate diretamente o parceiro comercial/suporte em sua região ou envie um e-mail para contato@e-precise.com.br solicitando um contato.' });
  //       return r;
  //     },
  //     e => {
  //       return null;
  //     }
  //   );
  // }

  // getRemainingDays(date: Date): any{
  //   var oneDay = 1000 * 60 * 60 * 24;
  //   var now = new Date();
  //   var dif = date.getTime() - now.getTime();
  //   return Math.round(dif/oneDay);
  // }

  openHelp() {
    this.movideskService.openChat();
  }
}
