import { JsonEntity, SgvJson } from '@eceos/arch';
import { BrazilianAddress } from './../../core/address/address';
import {
  CarrierResponsibleData,
  DelivererResponsibleData,
  DeliveryOwnResponsibleData,
  DeliveryResponsibleData,
  WithoutDeliveryResponsibleData
} from './delivery-responsible-data';
import { DeliveryPolicy, DeliveryPolicySummary } from './policy/delivery-policy';

export class DeliveryDataSummary implements JsonEntity {
  constructor(
    public responsibleData: DeliveryResponsibleData = null,
    public value: number = null
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, { responsibleData: this.responsibleData.toJson() });
  }

  static fromJson(json: any): DeliveryDataSummary {
    return json
      ? SgvJson.from.simple(json, DeliveryDataSummary, {
          responsibleData: DeliveryResponsibleData.fromJson(json.responsibleData)
        })
      : null;
  }
}

export class DeliveryData implements JsonEntity {
  constructor(
    public policy: DeliveryPolicySummary = null,
    public address: BrazilianAddress = new BrazilianAddress(),
    public responsibleData: DeliveryResponsibleData = new WithoutDeliveryResponsibleData(),
    public value: number = null
  ) {}

  get isWithoutResponsibleData(): boolean {
    return this.responsibleData instanceof WithoutDeliveryResponsibleData;
  }

  get isCarrierResponsibleData(): boolean {
    return this.responsibleData instanceof CarrierResponsibleData;
  }

  get isDelivererResponsibleData(): boolean {
    return this.responsibleData instanceof DelivererResponsibleData;
  }

  get isDeliveryOwnResponsibleData(): boolean {
    return this.responsibleData instanceof DeliveryOwnResponsibleData;
  }

  publishPolicyAndResponsibleFromPolicy(policy: DeliveryPolicy) {
    if (policy) {
      this.policy = policy.toSummary();
      this.responsibleData = policy.responsible.toResponsibleData();
    } else {
      this.policy = null;
      this.responsibleData = new WithoutDeliveryResponsibleData();
    }
  }

  isValid(): boolean {
    return (
      this.policy !== null &&
      this.address != null &&
      this.responsibleData !== null &&
      this.responsibleData.isValid() &&
      this.value != null
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      policy: this.policy.toJson(),
      address: this.address.toJson(),
      responsibleData: this.responsibleData.toJson()
    });
  }

  static fromJson(json: any): DeliveryData {
    return json
      ? SgvJson.from.simple(json, DeliveryData, {
          policy: DeliveryPolicySummary.fromJson(json.policy),
          address: BrazilianAddress.fromJson(json.address),
          responsibleData: DeliveryResponsibleData.fromJson(json.responsibleData)
        })
      : null;
  }
}
