import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { PercentAddition, Sale, ValueAddition } from '@eceos/domain';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-payment-addition',
  templateUrl: './payment-addition.component.html',
  styleUrls: ['./payment-addition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentAdditionComponent implements OnInit, OnDestroy {
  private _sale: Sale;

  private percentChange$: EventEmitter<number> = new EventEmitter();

  private valueChange$: EventEmitter<number> = new EventEmitter();

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.percentChange$
      .pipe(distinctUntilChanged(), debounceTime(300), untilDestroyed(this))
      .subscribe(percent => {
        this.publishPercent(percent);
        this.changeDetector.markForCheck();
      });
    this.valueChange$
      .pipe(distinctUntilChanged(), debounceTime(300), untilDestroyed(this))
      .subscribe(value => {
        this.publishValue(value);
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  get sale(): Sale {
    return this._sale;
  }

  @Input()
  set sale(sale: Sale) {
    if (this._sale !== sale) {
      this._sale = sale;
    }
  }

  get value(): number {
    return this.sale.addition.getValue(this.sale.totalOfItems);
  }

  set value(value: number) {
    this.valueChange$.emit(value);
  }

  get percent(): number {
    return this.sale.addition.getPercent(this.sale.totalOfItems);
  }

  set percent(percent: number) {
    this.percentChange$.emit(percent);
  }

  private publishPercent(percent: number) {
    if (this.sale.addition instanceof PercentAddition) {
      this.sale.addition.value = percent;
    } else {
      this.sale.asPercentAddition(percent);
    }
  }

  private publishValue(value: number) {
    if (this.sale.addition instanceof ValueAddition) {
      this.sale.addition.value = value;
    } else {
      this.sale.asValueAddition(value);
    }
  }
}
