import { MatSelectChange } from '@angular/material/select';
import { ProductSelectorMode } from '../../sales/sale-details/product-selector-mode';
import { SettingsService } from '../../../core/settings.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sale-ui-settings',
  templateUrl: './sale-ui-settings.component.html',
  styleUrls: ['./sale-ui-settings.component.scss']
})
export class SaleUiSettingsComponent implements OnInit {
  constructor(private repository: SettingsService) {}

  ngOnInit() {}

  get defaultProductSelectorMode(): ProductSelectorMode {
    return this.repository.defaultProductSelectorMode;
  }

  onProductModeSelected(event: MatSelectChange) {
    this.repository.defaultProductSelectorMode = event.value;
  }

  compareById(obj1: ProductSelectorMode, obj2: ProductSelectorMode) {
    return obj1 === obj2;
  }
  get simpleMode(): ProductSelectorMode {
    return ProductSelectorMode.SIMPLE;
  }

  get touchMode(): ProductSelectorMode {
    return ProductSelectorMode.TOUCH;
  }
}
