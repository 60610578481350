<app-layout-back [hotkeys]="hotkeys">
  <section class="content">
    <mat-card class="headerCard">
      <mat-card-title> <small> Entrega </small> </mat-card-title>
      <mat-card-content>
        <button
          mat-raised-button
          [class.selected]="!deliveryData"
          (click)="withoutDeliveryClick()"
          [disabled]="!sale"
        >
          (F3) ENTREGA NO BALCÃO
        </button>
        <button
          mat-raised-button
          [class.selected]="deliveryData"
          (click)="withDeliveryClick()"
          [disabled]="!sale"
        >
          (F4) ENTREGA Á DOMICÍLIO
        </button>
      </mat-card-content>
    </mat-card>

    <mat-card class="deliveryDataCard" *ngIf="deliveryData">
      <mat-card-content>
        <app-data-selector
          label="Política de Entrega"
          [repository]="deliveryPoliciesRepository"
          [(ngModel)]="deliveryData.policy"
          (ngModelChange)="onDeliveryPolicyChange($event)"
          class="policy"
        ></app-data-selector>

        <mat-form-field>
          <mat-label>Responsável</mat-label>
          <mat-select
            [(ngModel)]="selectedResponsibleDataOption"
            (ngModelChange)="onSelectedResponsibleDataOptionChange($event)"
            [compareWith]="compareById"
          >
            <mat-option
              *ngFor="let option of responsibleDataOptions; trackBy: trackById"
              [value]="option"
            >
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <app-data-selector
          label="Transportadora"
          [repository]="carriersRepository"
          [(ngModel)]="$any(deliveryData.responsibleData).carrier"
          [required]="true"
          *ngIf="deliveryData.isCarrierResponsibleData"
        ></app-data-selector>

        <app-data-selector
          label="Entregador"
          [repository]="deliverersRepository"
          [(ngModel)]="$any(deliveryData.responsibleData).deliverer"
          [required]="true"
          *ngIf="deliveryData.isDelivererResponsibleData"
        ></app-data-selector>

        <mat-form-field>
          <mat-label>Valor da Entrega</mat-label>
          <input
            matInput
            currencyMask
            [(ngModel)]="deliveryData.value"
            [options]="{ allowNegative: false, nullable: true }"
            required
          />
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card class="addressCard" *ngIf="deliveryData">
      <mat-card-title><small>Endereço</small></mat-card-title>
      <mat-card-content>
        <app-brazilian-address-form
          #addressForm="ngModel"
          [(ngModel)]="deliveryData.address"
          [required]="true"
        ></app-brazilian-address-form>
      </mat-card-content>
    </mat-card>
  </section>

  <button mat-fab class="floatDefault" *ngIf="nextEnabled()" (click)="submitAndNext()">
    <mat-icon>done</mat-icon>
  </button>
</app-layout-back>
