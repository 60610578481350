import { Injectable } from '@angular/core';
import { RemoteWeighingMachineSummary } from './remote-weighing-machine';
import { ApiService, ReadonlyRepositoryImpl } from '@eceos/arch';

@Injectable({ providedIn: 'root' })
export class RemoteWeighingMachinesRepository extends ReadonlyRepositoryImpl<
  RemoteWeighingMachineSummary,
  RemoteWeighingMachineSummary
> {
  constructor(api: ApiService) {
    super(
      api.root.path('remoteHw/weighingMachines'),
      RemoteWeighingMachineSummary,
      RemoteWeighingMachineSummary
    );
  }
}
