<app-layout-menu [loading]="loadingService.loading$ | async">
  <section class="actions">
    <button mat-icon-button (click)="list.invalidate()">
      <mat-icon>cached</mat-icon>
    </button>
  </section>
  <entity-cards #list [repository]="pendingRepository" [perspective]="perspective" [summary]="true">
    <mat-card *entityCard="let entity" (click)="selectSale(entity)">
      <section class="status" matTooltip="Criada em {{ entity.date | date: 'short' }}">
        {{ entity.date | amTimeAgo }}
      </section>
      <mat-card-header>
        <mat-card-title> Venda nº {{ entity.key }} </mat-card-title>
        <mat-card-subtitle>
          <span *ngIf="!entity.vendor">Sem vendedor</span>
          <span *ngIf="entity.vendor" matTooltip="Vendedor responsável">
            {{ entity.vendor.name }}
          </span>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <section
          *ngIf="entity.client"
          class="client line"
          matTooltip="Cliente"
          matTooltipShowDelay="100"
        >
          <mat-icon>person</mat-icon>
          <div>{{ entity.client.name }}</div>
        </section>
        <ng-container
          *ngIf="entity.deliveryData || (currentPdvProfileService.profile$ | async)?.withDelivery"
        >
          <section matTooltip="Entrega" matTooltipShowDelay="100">
            <mat-icon svgIcon="shipping-fast"></mat-icon>
            <div>{{ entity.deliveryData ? 'Entrega a Domicílio' : 'Entrega no Balcão' }}</div>
          </section>

          <ng-container *ngIf="entity.deliveryData">
            <section
              matTooltip="Entregador"
              matTooltipShowDelay="100"
              *ngIf="entity.deliveryData.responsibleData?.deliverer"
            >
              <mat-icon svgIcon="motorcycle"></mat-icon>
              <div>{{ entity.deliveryData.responsibleData.deliverer.name }}</div>
            </section>

            <section
              matTooltip="Transportadora"
              matTooltipShowDelay="100"
              *ngIf="entity.deliveryData.responsibleData?.carrier"
            >
              <mat-icon svgIcon="truck"></mat-icon>
              <div>{{ entity.deliveryData.responsibleData.carrier.name }}</div>
            </section>
          </ng-container>
        </ng-container>
        <section class="value line" matTooltip="Valor total" matTooltipShowDelay="100">
          <mat-icon svgIcon="money-bill"></mat-icon>
          <div>{{ entity.value | currency: 'BRL' }}</div>
        </section>
      </mat-card-content>
    </mat-card>
  </entity-cards>
  <button mat-fab class="floatDefault" color="accent" (click)="goNext()">
    <mat-icon>add</mat-icon>
  </button>
</app-layout-menu>
