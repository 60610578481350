import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../../core/settings.service';

@Component({
  selector: 'app-home',
  template: ` <p>Redirecionando</p> `
})
export class HomeComponent implements OnInit {
  constructor(
    private settings: SettingsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.router.navigateByUrl(this.settings.defaultHome.path);
  }
}
