import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '@eceos/arch';
import { EntityCardsComponent, SimpleEntityListPerspective } from '@eceos/common-components';
import { Hotkey, HotkeySet } from '@eceos/common-utils';
import { CurrentPdvProfileService, Sale, SalesRepository, SaleSummary } from '@eceos/domain';

@Component({
  selector: 'app-sale-list',
  templateUrl: './sale-list.component.html',
  styleUrls: ['./sale-list.component.scss']
})
export class SaleListComponent implements OnInit, OnDestroy {
  @ViewChild(EntityCardsComponent, { static: true }) private list: EntityCardsComponent;

  private hotkeys = HotkeySet.of([
    Hotkey.key('f5')
      .description('Atualizar a listagem de vendas pendentes')
      .do(() => this.list.invalidate()),
    Hotkey.key('f8')
      .key('ctrl+enter')
      .description('Abrir uma nova venda')
      .do(() => this.goNext())
  ]);

  pendingRepository = this.repository.onlyPending();

  perspective = new SimpleEntityListPerspective();

  constructor(
    private repository: SalesRepository,
    private router: Router,
    public loadingService: LoadingService,
    public currentPdvProfileService: CurrentPdvProfileService
  ) {}

  ngOnInit() {
    this.hotkeys.enable();
  }
  ngOnDestroy() {
    this.hotkeys.disable();
  }

  selectSale(sale: SaleSummary | Sale): void {
    this.router.navigateByUrl(`/sales/${sale.id}`);
  }

  goNext(): void {
    this.router.navigateByUrl('/sales/new');
  }
}
