import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading$ = this.loadingBar.progress$.pipe(map(v => v > 0));

  constructor(private loadingBar: LoadingBarService) {}
}
