<div class="line">
  <mat-form-field thin class="inputContainer">
    <mat-label>{{documentPlaceHolder}}</mat-label>
    <mat-select
      mainFocus
      tabindex="0"
      class="inputContainer"
      [(ngModel)]="document"
    >
      <mat-option *ngFor="let fd of documents" [value]="fd">{{ fd.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field thin class="inputContainer valueContainer">
    <button matPrefix class="completBtn" mat-button (click)="onCompleteClicked()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <input class="value" matInput currencyMask [(ngModel)]="value" />
  </mat-form-field>
</div>
