import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LayoutBackModule } from './layout-back/layout-back.module';
import { LayoutMenuModule } from './layout-menu/layout-menu.module';
import { DefaultSharedModule } from '@eceos/default-shared';

@NgModule({
  imports: [CommonModule, DefaultSharedModule, LayoutBackModule, LayoutMenuModule],
  exports: [LayoutMenuModule, LayoutBackModule]
})
export class LayoutModule {}
