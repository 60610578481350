<h1 mat-dialog-title>Observações</h1>
<div mat-dialog-content>
  <mat-form-field>
    <textarea
      matInput
      [(ngModel)]="value"
      cdkFocusInitial
      matTextareaAutosize
      matAutosizeMinRows="5"
      matAutosizeMaxRows="10"
    ></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="send()"><mat-icon>done</mat-icon>Salvar</button>
  <button mat-button (click)="cancel()">Cancelar</button>
</div>
