import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { ClientSummary } from '../clients';
import { GuestCardSummary } from '../guest-cards/guest-card';
import { Operatable } from '../operatables';
import { OutboundOperation } from '../operations/outbound-operation';
import { OutbondOperationItem } from '../operations/outbound-operation-item';
import { VendorSummary } from '../vendors';
import { Addition } from './addition';
import { DeliveryData, DeliveryDataSummary } from './delivery/delivery-data';
import { PaymentDiscount } from './payment-discount';
import { PaymentForm } from './payment-form';
import { SaleState } from './sale-state';

export class SaleSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly key = '',
    public readonly date = new Date(),
    public readonly value = 0.0,
    public readonly client: ClientSummary = null,
    public readonly vendor: VendorSummary = null,
    public readonly deliveryData: DeliveryDataSummary = null
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      client: this.client.toJson(),
      vendor: this.vendor.toJson(),
      deliveryData: this.deliveryData.toJson()
    });
  }
  static fromJson(json: any): SaleSummary {
    return json
      ? SgvJson.from.simple(json, SaleSummary, {
          client: ClientSummary.fromJson(json.client),
          vendor: VendorSummary.fromJson(json.vendor),
          deliveryData: DeliveryDataSummary.fromJson(json.deliveryData)
        })
      : null;
  }
}

export class SaleItem extends OutbondOperationItem implements DomainEntity {
  public readonly id = SgvId.gen();

  constructor(
    operatable: Operatable = null,
    amount = 1,
    unitaryPrice = 0,
    discountValue = 0
  ) {
    super(operatable, amount, unitaryPrice, discountValue);
  }

  get isWeightable(): boolean {
    return this.operatable && this.operatable.defaultUnity.isWeightable;
  }

  toJson(): any {
    return SgvJson.to.simple(this, { operatable: this.operatable.toJson() });
  }

  clone(): SaleItem {
    return SaleItem.fromJson(this.toJson())
  }

  static fromJson(json: any): SaleItem {
    return json
      ? SgvJson.from.simple(json, SaleItem, {
          operatable: Operatable.fromJson(json.operatable)
        })
      : null;
  }

  get isStockAvailable(){
    return this.operatable?.isStockAvailable ?? true;
  }

  get isStockEnough(){
    if (this.operatable?.stock){
      return this.amount <= this.operatable.stock.available &&
        this.operatable.stock.available > 0;
    }
    return true;
  }

}

export class Sale extends OutboundOperation implements DomainEntity {
  public readonly id = SgvId.gen();
  public key = '';
  public date = null;
  public items: SaleItem[] = [];
  public client: ClientSummary = null;
  public cnp: string = null;
  public vendor: VendorSummary = null;
  public guestCard: GuestCardSummary = null;
  public details = '';
  public state = SaleState.PENDENTE;
  public deliveryData: DeliveryData = null;

  get lastItem(): { value?: SaleItem; empty: boolean } {
    if (this.items.length === 0) {
      return { empty: true };
    }
    return {
      value: this.items[this.items.length - 1],
      empty: false
    };
  }

  get totalFreight(): number {
    return this.deliveryData ? this.deliveryData.value : 0;
  }

  get totalItems(): number {
    let total = 0;
    this.items.forEach(item => {
      total += item.amount;
    })
    return total;
  }

  isFinalized(): boolean {
    return this.state === SaleState.FINALIZADA;
  }

  isPending(): boolean {
    return this.state === SaleState.PENDENTE;
  }

  isCanceled(): boolean {
    return this.state === SaleState.CANCELADA;
  }

  isWithoutDeliveryData(): boolean {
    return this.deliveryData === null;
  }

  isWithDeliveryData(): boolean {
    return this.deliveryData !== null;
  }

  withoutDelivery(): void {
    this.deliveryData = null;
  }

  withDelivery(): DeliveryData {
    return (this.deliveryData = new DeliveryData());
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      items: SgvJson.to.array(this.items),
      client: SgvJson.to.optional(this.client),
      vendor: SgvJson.to.optional(this.vendor),
      guestCard: SgvJson.to.optional(this.guestCard),
      payment: SgvJson.to.optional(this.payment),
      discount: SgvJson.to.optional(this.discount),
      state: this.state.name,
      addition: SgvJson.to.optional(this.addition),
      deliveryData: SgvJson.to.optional(this.deliveryData)
    });
  }

  static fromJson(data: any): Sale {
    return SgvJson.from.simple(data, Sale, {
      items: SgvJson.from.array(data.items, SaleItem.fromJson),
      client: ClientSummary.fromJson(data.client),
      vendor: VendorSummary.fromJson(data.vendor),
      guestCard: GuestCardSummary.fromJson(data.guestCard),
      payment: PaymentForm.fromJson(data.payment),
      discount: PaymentDiscount.fromJson(data.discount),
      state: SaleState.getOrNull(data.state),
      addition: Addition.fromJson(data.addition),
      deliveryData: DeliveryData.fromJson(data.deliveryData)
    });
  }
}
