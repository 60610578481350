import { Injectable } from '@angular/core';
import {
  ApiService,
  InterceptorConfig,
  ReadonlyRepositoryImpl,
  RepositoryImpl,
  RequestMetadata,
  RequestMetadataUtils
} from '@eceos/arch';
import { RemotePrinterSummary, ReportFormat } from '@eceos/domain';
import { Observable } from 'rxjs';
import { Sale, SaleSummary } from './sale';

@Injectable({ providedIn: 'root' })
export class SalesRepository extends RepositoryImpl<SaleSummary, Sale> {
  constructor(api: ApiService) {
    super(api.root.path('/sales'), SaleSummary, Sale);
  }

  onlyPending(): ReadonlyRepositoryImpl<SaleSummary, Sale> {
    return new ReadonlyRepositoryImpl<SaleSummary, Sale>(
      this.api.path('pending'),
      SaleSummary,
      Sale
    );
  }

  finalize(
    sale: Sale,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<Response> {
    return this.api
      .path(sale.id)
      .path('finalize')
      .postJson(sale.toJson(), { metadata: RequestMetadataUtils.merge(metadata, this.metadata) });
  }

  printTo(
    sale: Sale | SaleSummary,
    printer: RemotePrinterSummary,
    format: ReportFormat = ReportFormat.PDF,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<Response> {
    const form = new URLSearchParams();
    form.set('printerId', printer.id);
    return this.api
      .path(sale.id)
      .path('receipt.' + format.value)
      .path('printTo')
      .postForm(form, {
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      });
  }

  printItemTicketTo(
    sale: Sale | SaleSummary,
    printer: RemotePrinterSummary,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<Response> {
    const form = new URLSearchParams();
    form.set('printerId', printer.id);
    return this.api
      .path(sale.id)
      .path('ticket.escpos')
      .path('printTo')
      .postForm(form, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) });
  }
}
