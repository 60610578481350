import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SettingsComponent } from './settings.component';
import { AuthGuard } from '@eceos/arch';

export const routes: Routes = [
  { path: 'settings', component: SettingsComponent, data: { title: 'e-Ceos PDV - Configurações' } , canActivate: [AuthGuard] }
];

export const SettingsRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
