import { Injectable } from '@angular/core';
import { ProductSelectorMode } from '../features/sales/sale-details/product-selector-mode';
import {
  RemotePrinterSummary,
  RemoteWeighingMachineSummary,
  VendorSummary,
  ReportFormat
} from '@eceos/domain';

const defaultReceiptPrinterKey = 'defaultPrinter.receipts';
const defaultWeighingMachineKey = 'defaultWeighingMachine';
const defaultProductSelectorModeKey = 'defaultProductSelectorMode';
const defaultVendorKey = 'defaultVendor';
const defaultHomeKey = 'defaultHome';
const defaultPrintFormatKey = 'defaultPrintFormat';

export class PdvHome {
  static readonly SALES = new PdvHome('SALES', '/sales');
  static readonly GUEST_CARDS = new PdvHome('GUEST_CARDS', '/guestCards');

  private constructor(readonly id: string, readonly path: string) {}

  static values() {
    return [this.SALES, this.GUEST_CARDS];
  }
  static fromId(id: string) {
    return this.values().find(v => v.id === id);
  }
}

@Injectable()
export class SettingsService {
  private _defaultReceiptPrinter: RemotePrinterSummary;

  private _defaultWeighingMachine: RemoteWeighingMachineSummary;

  private _defaultProductSelectorMode: ProductSelectorMode;

  private _defaultVendor: VendorSummary;

  private _defaultHome: PdvHome = PdvHome.SALES;

  private _defaultPrintFormat: ReportFormat = ReportFormat.PDF;

  constructor() {
    this.loadDefaultReceiptPrinter();
    this.loadDefaultWeighingMachine();
    this.loadDefaultProductSelectorMode();
    this.loadDefaultVendor();
    this.loadDefaultHome();
    this.loadDefaultPrintFormat();
  }

  get defaultReceiptPrinter(): RemotePrinterSummary {
    return this._defaultReceiptPrinter;
  }

  set defaultReceiptPrinter(printer: RemotePrinterSummary) {
    if (printer !== this._defaultReceiptPrinter) {
      if (printer) {
        localStorage.setItem(defaultReceiptPrinterKey, JSON.stringify(printer.toJson()));
      } else {
        localStorage.removeItem(defaultReceiptPrinterKey);
      }
      this._defaultReceiptPrinter = printer;
    }
  }

  get defaultWeighingMachine(): RemoteWeighingMachineSummary {
    return this._defaultWeighingMachine;
  }

  set defaultWeighingMachine(weighingMachine: RemoteWeighingMachineSummary) {
    if (weighingMachine !== this._defaultWeighingMachine) {
      if (weighingMachine) {
        localStorage.setItem(defaultWeighingMachineKey, JSON.stringify(weighingMachine.toJson()));
      } else {
        localStorage.removeItem(defaultWeighingMachineKey);
      }
      this._defaultWeighingMachine = weighingMachine;
    }
  }

  get defaultProductSelectorMode(): ProductSelectorMode {
    return this._defaultProductSelectorMode;
  }

  set defaultProductSelectorMode(printer: ProductSelectorMode) {
    if (printer !== this._defaultProductSelectorMode) {
      if (printer) {
        localStorage.setItem(defaultProductSelectorModeKey, JSON.stringify(printer));
      } else {
        localStorage.removeItem(defaultProductSelectorModeKey);
      }
      this._defaultProductSelectorMode = printer;
    }
  }

  get defaultVendor(): VendorSummary {
    return this._defaultVendor;
  }

  set defaultVendor(vendor: VendorSummary) {
    if (vendor !== this._defaultVendor) {
      if (vendor) {
        localStorage.setItem(defaultVendorKey, JSON.stringify(vendor.toJson()));
      } else {
        localStorage.removeItem(defaultVendorKey);
      }
      this._defaultVendor = vendor;
    }
  }

  get defaultHome(): PdvHome {
    return this._defaultHome;
  }

  set defaultHome(home: PdvHome) {
    if (home !== this._defaultHome) {
      if (home) {
        localStorage.setItem(defaultHomeKey, home.id);
      } else {
        localStorage.removeItem(defaultHomeKey);
      }
      this._defaultHome = home;
    }
  }

  get defaultPrintFormat(): ReportFormat {
    return this._defaultPrintFormat;
  }

  set defaultPrintFormat(format: ReportFormat) {
    if (format !== this._defaultPrintFormat) {
      if (format) {
        localStorage.setItem(defaultPrintFormatKey, format.name);
      } else {
        localStorage.removeItem(defaultPrintFormatKey);
      }
      this._defaultPrintFormat = format;
    }
  }

  private loadDefaultReceiptPrinter() {
    try {
      const json = localStorage.getItem(defaultReceiptPrinterKey);
      if (json) {
        this._defaultReceiptPrinter = RemotePrinterSummary.fromJson(JSON.parse(json));
      }
    } catch (e) {
      console.error('Falha ao carregar a impressora padrão de recibos', e);
    }
  }
  private loadDefaultWeighingMachine() {
    try {
      const json = localStorage.getItem(defaultWeighingMachineKey);
      if (json) {
        this._defaultWeighingMachine = RemoteWeighingMachineSummary.fromJson(JSON.parse(json));
      }
    } catch (e) {
      console.error('Falha ao carregar a balança padrão', e);
    }
  }
  private loadDefaultProductSelectorMode() {
    try {
      const value: string = localStorage.getItem(defaultProductSelectorModeKey);
      if (value) {
        this._defaultProductSelectorMode = Number.parseInt(value, 10);
        return;
      }
    } catch (e) {
      console.error('Falha ao carregar o modo padrão de seleção de produtos', e);
    }
    this._defaultProductSelectorMode = ProductSelectorMode.SIMPLE;
  }
  private loadDefaultVendor() {
    try {
      const json = localStorage.getItem(defaultVendorKey);
      if (json) {
        this._defaultVendor = VendorSummary.fromJson(JSON.parse(json));
      }
    } catch (e) {
      console.error('Falha ao carregar o vendedor padrão', e);
    }
  }

  private loadDefaultHome() {
    try {
      const value = PdvHome.fromId(localStorage.getItem(defaultHomeKey));
      if (value) {
        this._defaultHome = value;
      }
    } catch (e) {
      console.error('Falha ao carregar o home padrão deste PDV', e);
    }
  }

  private loadDefaultPrintFormat() {
    try {
      const value: string = localStorage.getItem(defaultPrintFormatKey);
      if (value) {
        this._defaultPrintFormat = ReportFormat.get(value);
        return;
      }
    } catch (e) {
      console.error('Falha ao carregar o formato padrão de impressão!', e);
    }
    this._defaultPrintFormat = ReportFormat.PDF;
  }
}
