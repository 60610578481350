import { Pipe, PipeTransform } from '@angular/core';
import { SaleItem } from '@eceos/domain';
import { MathUtils } from '@eceos/common-utils';

@Pipe({
  name: 'saleItemAmountSummary',
  pure: false
})
export class SaleItemAmountSummaryPipe implements PipeTransform {
  transform(value: SaleItem[]): any {
    const sum = this.sumarize(value);
    return Array.from(sum.entries())
      .map(([k, v]) => `${v} ${k}`)
      .join(', ');
  }

  private sumarize(value: SaleItem[]): Map<string, number> {
    return value.reduce((acc, v) => {
      const u = v.operatable.defaultUnity.acronym;
      acc.set(u, MathUtils.round((acc.get(u) || 0) + v.amount));
      return acc;
    }, new Map());
  }
}
