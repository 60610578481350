<div class="content" (keydown)="onKeydown($event)">
<mat-form-field>
  <mat-label>Quantidade</mat-label>
  <input 
  #amountField
  *ngIf="!inputButtons"
  class="quantity"
  type="number" 
  matInput
  [(ngModel)]="amount"
  [disabled]="!active"
  (blur)="validateAmount(amountField)"
  />
  <input 
    *ngIf="inputButtons"
    class="quantity"
    #amountField
    matInput
    [(ngModel)]="amount"
    [disabled]="!active"
    (blur)="validateAmount(amountField)"
  />
  <button
    *ngIf="inputButtons"
    #minusButton
    tabindex="0"
    mat-icon-button
    matSuffix
    (click)="decreaseAmount()"
    (click)="quantityButtonCLick($event)"
  >
    <mat-icon>remove</mat-icon>
  </button>
  <button
    type="button"
    *ngIf="inputButtons"
    #addButton
    tabindex="0"
    matSuffix
    mat-icon-button
    (click)="increaseAmount()"
    (click)="quantityButtonCLick($event)"
    >
    <mat-icon>add</mat-icon>
  </button>
  <button
    mat-icon-button
    matSuffix
    *ngIf="weightable && hasDefaultWeighingMachine"
    (click)="getWeight()"
    matTooltip="Obter peso!"
  >
    <mat-icon>cached</mat-icon>
  </button>
</mat-form-field>
<mat-form-field>
  <mat-label>Valor Unitário</mat-label>
  <input
    #unitaryPriceField
    matInput
    currencyMask
    [(ngModel)]="unitaryPrice"
    [disabled]="(!active || (isPriceChangeRestricted$ | async))"
  />
</mat-form-field>
<mat-form-field>
  <mat-label>Subtotal</mat-label>
  <input
    matInput
    currencyMask
    readonly
    [ngModel]="total"
    disabled="true"
  />
</mat-form-field>
</div>