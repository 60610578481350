<div class="content" *ngIf="sale">
  <section class="top">
    <mat-card thinBorder class="search">
      <mat-form-field thin floatLabel="always">
        <mat-label>Código de Barras</mat-label>
        <input
          #search
          [(ngModel)]="currentQuery"
          appBarcode
          autofocus
          autocomplete="off"
          matInput
          (keyup.enter)="onSearchSubmit()"
        />
        <button 
        mat-icon-button
        matSuffix
        tabindex="-1" 
        (click)="toggleOperatableDialog()"
      >
        <mat-icon>search</mat-icon>
      </button>
      </mat-form-field>
    </mat-card>

  </section>

  <section class="middle">
    <mat-card thinBorder class="itemDetails">
      <app-sale-item-detail
        [item]="currentItem"
        (itemChange)="notifyChange()"
        (complete)="search.focus()"
        (weighing)="weighingListener($event)"
        (onEnter)="onEnter($event)"
      ></app-sale-item-detail>
      <button mat-button class="red-btn" (click)="removeCurrentItem()" [disabled]="!currentItem">
        Remover
      </button>
    </mat-card>
    <mat-card class="itensList">
      <mat-card-content>
        <app-sale-item-list [items]="sale.items" [(selected)]="currentItem"></app-sale-item-list>
      </mat-card-content>
    </mat-card>
  </section>

  <section class="bottom">
    <mat-card thinBorder class="totalOfItems">
      <mat-form-field thin>
        <mat-label>Qtd. de Itens</mat-label>
        <input matInput readonly [value]="sale.items | saleItemAmountSummary" />
      </mat-form-field>
    </mat-card>
    <mat-card thinBorder class="totalValue">
      <mat-form-field thin>
        <mat-label>Total dos Itens</mat-label>
        <input matInput readonly [value]="sale.totalOfItems | currency: 'BRL':'symbol'" />
      </mat-form-field>
    </mat-card>
  </section>
</div>
