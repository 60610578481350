export interface BreadcrumbItem {
    id: string;
    name: string;
    clickable: boolean;
    isHome?: boolean;
    current?: boolean;
}

export class Breadcrumb {
    constructor(
        public breadcrumbItems: BreadcrumbItem[] = []
    ) { }

    public resetBreadcrumb() {
        if (this.breadcrumbItems.length > 0) {
            this.breadcrumbItems.splice(0, this.breadcrumbItems.length);
        }
    }
}