<div *ngIf="!loading" class="breadcrumb">
    <div *ngFor="let bc of breadcrumb.breadcrumbItems; trackBy: trackById" class="breadcrumb-item">
        <div *ngIf="bc?.isHome && bc?.clickable" (click)="onBreadcrumbItemClick(bc)" class="navigable home">
            <mat-icon>home</mat-icon>
        </div>
        <div *ngIf="!bc?.isHome && !bc?.clickable">
            {{bc?.name}}
        </div>
        <div (click)="onBreadcrumbItemClick(bc)" class="navigable" *ngIf="!bc.isHome && bc?.clickable">
            {{bc?.name}}
        </div>
        <div *ngIf="!bc?.current" class="next">
            >
        </div>
    </div>
</div>