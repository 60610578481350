<app-layout-back #layout [backPath]="pdvHome.path" [hotkeys]="hotkeys">
  <section class="content">
    <mat-card class="actionsCard">
      <mat-card-header>
        <mat-card-title>Ações</mat-card-title>
      </mat-card-header>
      <mat-card-content class="actions">
        <div class="sideOptionsButton">
          <button mat-raised-button class="blue-btn" (click)="printSale()">
            Imprimir Venda (F2)
          </button>
          <button mat-raised-button class="viewBtn blue-btn" [matMenuTriggerFor]="viewSalePrint">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #viewSalePrint>
            <button mat-menu-item (click)="navigateTo(viewSaleCuponPath)">Visualizar Cupom</button>
            <button mat-menu-item (click)="navigateTo(viewSaleA4Path)">Visualizar A4</button>
          </mat-menu>
        </div>
        <button
          mat-raised-button
          class="teal-btn"
          *ngIf="!hasAuthorizedNF"
          [disabled]="isSyncNFe"
          (click)="emitNFCe()"
        >
          Emitir NFC-e (F3)
        </button>
        <div class="sideOptionsButton" *ngIf="hasAuthorizedNF && isNFCe">
          <button mat-raised-button class="teal-btn" (click)="printDanfe()">
            Imprimir NFC-e (F3)
          </button>
          <button
            mat-raised-button
            class="viewBtn teal-btn"
            [matMenuTriggerFor]="viewNFCePrint"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #viewNFCePrint>
            <button mat-menu-item (click)="navigateTo(viewNFCePath)">Visualizar NFC-e</button>
            <button mat-menu-item (click)="navigateTo(downloadNFeXmlPath)">XML NFC-e</button>
          </mat-menu>
        </div>

        <button
          mat-raised-button
          class="cyan-btn"
          *ngIf="!hasAuthorizedNF && canTrasmitNFe"
          [disabled]="isSyncNFe"
          (click)="emitNFe()"
        >
          Emitir NF-e (F5)
        </button>
        <div class="sideOptionsButton" *ngIf="hasAuthorizedNF && isNFe">
          <button mat-raised-button class="cyan-btn" (click)="printDanfe()">
            Imprimir NF-e (F5)
          </button>
          <button mat-raised-button class="viewBtn cyan-btn" [matMenuTriggerFor]="viewNFePrint">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #viewNFePrint>
            <button mat-menu-item (click)="navigateTo(viewNFePath)">Visualizar NF-e</button>
            <button mat-menu-item (click)="navigateTo(downloadNFeXmlPath)">XML NF-e</button>
          </mat-menu>
        </div>

        <button
          mat-raised-button
          class="dpurple-btn"
          *ngIf="hasAuthorizedNF"
          (click)="emailDanfe()"
        >
          Enviar NF-e/NFC-e por e-mail (F4)
        </button>

        <button
          mat-raised-button
          class="bluegray-btn"
          [matMenuTriggerFor]="viewFinancialMovementPrint"
        >
          Outras Impressões
        </button>
        <mat-menu #viewFinancialMovementPrint class="auto-width">
          <button mat-menu-item (click)="printFinancialMovement()">
            Imprimir Movimentação Financeira (F6)
          </button>
          <button mat-menu-item (click)="onViewFinancialMovementPDF()">
            Visualizar A4 Movimentação Financeira (F7)
          </button>
          <button mat-menu-item (click)="onSaleItemTicketPrint()">
            Imprimir Ficha de Consumo (F9)
          </button>
        </mat-menu>
        <button #newSaleBtn mat-raised-button class="green-btn" routerLink="/sales/new">
          Nova Venda (F8)
        </button>
        <button mat-button class="red-btn" (click)="layout.goBack()">
          Voltar para a lista (ESC)
        </button>
      </mat-card-content>
    </mat-card>
  </section>
</app-layout-back>
