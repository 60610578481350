import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, debounceTime } from 'rxjs/operators';
import { Sale, SaleItem, BarcodeService, Operatable } from '@eceos/domain';
import { WeightService } from '../../../../core/weighing-machines/weight.service';
import { InterceptorConfig } from '@eceos/arch';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OperatableDialogComponent } from '../operatable-dialog/operatable-dialog.component';
import { Hotkey, HotkeySet } from '@eceos/common-utils';

@Component({
  selector: 'app-sale-details-mode-simple',
  templateUrl: './sale-details-mode-simple.component.html',
  styleUrls: ['./sale-details-mode-simple.component.scss']
})
export class SaleDetailsModeSimpleComponent implements OnInit, OnDestroy {
  hotkeys = HotkeySet.of([
    Hotkey.key('f3')
      .description('Alterar entre modos de seleção/leitura')
      .do(() => this.toggleOperatableDialog()),
  ]);
  sale: Sale;

  currentItem: SaleItem = null;

  currentQuery: string = '';

  @Output() weighing = new EventEmitter<boolean>();

  @Output() saleChange = new EventEmitter();

  @Output() enableParentHotkeys = new EventEmitter<boolean>();

  @ViewChild('search') searchInput: ElementRef;

  private queueChangeListener = new Subject();

  private queue: SaleItem[] = [];

  private ableToProcess = true;

  dialogRef: MatDialogRef<OperatableDialogComponent>

  constructor(
    private barcodeService: BarcodeService,
    private snackbar: MatSnackBar,
    private weightService: WeightService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.queueChangeListener
      .asObservable()
      .pipe(debounceTime(100))
      .subscribe(() => this.processQueue());
  }

  ngOnDestroy() {
    this.hotkeys.disable();
  }

  @Input('paused')
  set paused(paused: boolean) {
    this.ableToProcess = !paused;
    this.queueChangeListener.next(null);
  }

  @Input('sale')
  set currentSale(sale: Sale) {
    this.sale = sale;
    if (this.sale) {
      let item = null;
      if (this.currentItem) {
        item = sale.items.find(i => i.id === this.currentItem.id);
      } else {
        const last = this.sale.lastItem;
        if (!last.empty) {
          item = last.value;
        }
      }
      this.currentItem = item;
    }
  }

  onSearchSubmit(): void {
    const query = this.currentQuery;
    if (query.length > 2) {
      this.barcodeService
        .findByBarcode(query, { autoCatch: InterceptorConfig.NO_INTERCEPT })
        .pipe(
          catchError(_ => throwError(`Código de barras [${query}] não encontrado `)),
          map((result: any) => new SaleItem(result.operatable, result.amount)),
          switchMap((item: SaleItem) => {
            if (this.weightService.hasDefaultWeighingMachine && item.isWeightable) {
              this.weighing.emit(true);
              return this.weightService.getWeight(item).pipe(
                finalize(() => this.weighing.emit(false)),
                catchError(err => {
                  this.showError(err, err);
                  return of(item);
                })
              );
            } else {
              return of(item);
            }
          })
        )
        .subscribe(
          (item: SaleItem) => {
            this.queue.push(item);
            this.queueChangeListener.next(null);
          },
          (e: any) => this.showError(e, e)
        );
      this.currentQuery = '';
    }
  }
  processQueue(): void {
    if (this.ableToProcess) {
      let changed = false;
      while (this.queue.length > 0) {
        const item = this.queue.pop();
        this.sale.addItem(item);
        changed = true;
      }
      if (changed) {
        this.currentItem = this.sale.lastItem.value;
        this.notifyChange();
      }
    }
  }
  notifyChange(): void {
    this.saleChange.emit();
  }

  requestFocus() {
    this.searchInput.nativeElement.focus();
  }

  onEnter(inputRequestFocus: boolean) {
    if (inputRequestFocus) {
      this.requestFocus();
    }
  }

  removeCurrentItem() {
    if (this.currentItem) {
      this.sale.removeItem(this.currentItem);
      this.currentItem = null;
      this.notifyChange();
      this.requestFocus();
    }
  }

  weighingListener(value: boolean) {
    this.weighing.emit(value);
  }

  get amountOfItems(): number {
    return this.sale.items.length;
  }

  private showError(e: Error, message: string) {
    if (e) {
      console.error(e);
    }
    this.showMessage(message);
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 1000 });
  }

  toggleOperatableDialog() {
    if (!this.dialogRef) {
      this.operatableDialog();
    }
    else {
      this.dialogRef.close();
    }
  }

  operatableDialog() {
    this.enableParentHotkeys.emit(false)
    this.dialogRef = this.dialog.open(OperatableDialogComponent, {
      width: '100%',
      height: '90%',
      panelClass: 'custom-dialog'
    });
    let instance = this.dialogRef.componentInstance;
    instance.currentQuery = this.currentQuery;
    instance.focusInput = this.currentQuery ? true : false;
    this.dialogRef.afterClosed().subscribe((operatable: Operatable) => {
      this.enableParentHotkeys.emit(true)
      if (operatable) {
        this.onOperatableSelected(operatable[0]);
      }
      this.dialogRef = null;
      setTimeout(() => this.requestFocus(), 100);
    })
    this.currentQuery = '';
  }

  onOperatableSelected(op: Operatable) {
    const saleItem = new SaleItem(op, 1);
    if (saleItem.isWeightable && this.weightService.hasDefaultWeighingMachine) {
      this.weighing.emit(true);
      this.weightService
        .getWeight(saleItem)
        .pipe(
          finalize(() => this.weighing.emit(false)),
          catchError(err => {
            return of(saleItem);
          })
        )
        .subscribe(item => this.publishItem(item));
    } else {
      this.publishItem(saleItem);
    }
  }

  publishItem(saleItem: SaleItem) {
    this.queue.push(saleItem);
    this.queueChangeListener.next(null);
    setTimeout(() => this.requestFocus(), 100);
  }

}
