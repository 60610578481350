import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiPath, ApiService, AuthService, InterceptorConfig, RequestMetadata } from '@eceos/arch';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export enum LoginResult {
  EMPTY_USERNAME,
  EMPTY_PASSWORD,
  EMPTY_PDV_KEY,
  INVALID_USERNAME_FORMAT,
  LOGIN_FAIL,
  LOGIN_SUCCESS
}
interface PdvCredentials {
  login: string;
  password: string;
}
@Injectable()
export class LoginService {
  private api: ApiPath;

  private metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  constructor(apiRoot: ApiService, private router: Router, private authService: AuthService) {
    this.api = apiRoot.root;
  }

  validateLogin(pdvKey: string, username: string, password: string): Observable<LoginResult> {
    const result = new ReplaySubject<LoginResult>();
    if (!username) {
      result.next(LoginResult.EMPTY_USERNAME);
    } else if (!password) {
      result.next(LoginResult.EMPTY_PASSWORD);
    } else if (!pdvKey) {
      result.next(LoginResult.EMPTY_PDV_KEY);
    } else {
      const split = username.split('@');
      if (split.length !== 2) {
        result.next(LoginResult.INVALID_USERNAME_FORMAT);
      } else {
        this.searchPdvCredentials(result, pdvKey, split[0], split[1], password);
      }
    }
    return result;
  }
  private searchPdvCredentials(
    result: ReplaySubject<LoginResult>,
    pdvKey: string,
    user: string,
    enterprise: string,
    password: string
  ): void {
    const headers = new HttpHeaders({
      Authorization: `Basic ${btoa(`${user}@${enterprise}:${password}`)}`
    });

    this.api
      .path(`pdvs/key/${pdvKey}`)
      .getJson<PdvCredentials>({ headers, metadata: this.metadata })
      .pipe(
        catchError(e => {
          console.error(e);
          result.next(LoginResult.LOGIN_FAIL);
          return throwError(e);
        })
      )
      .subscribe(credentials => {
        if (credentials) {
          result.next(LoginResult.LOGIN_SUCCESS);
          this.searchPdvJwtToken(result, credentials, enterprise);
        } else {
          result.next(LoginResult.LOGIN_FAIL);
        }
      });
  }

  private searchPdvJwtToken(
    result: ReplaySubject<LoginResult>,
    credentials: PdvCredentials,
    enterprise: string
  ): void {
    const headers = new HttpHeaders({
      Authorization: `Basic ${btoa(`${credentials.login}@${enterprise}:${credentials.password}`)}`
    });

    this.api
      .path('login')
      .getText({ headers, metadata: this.metadata })
      .pipe(
        catchError(e => {
          console.error(e);
          result.next(LoginResult.LOGIN_FAIL);
          return throwError(e);
        })
      )
      .subscribe(token => {
        if (token) {
          result.next(LoginResult.LOGIN_SUCCESS);
          this.authService.publishLogin(token);
          this.router.navigate(['/']);
        } else {
          result.next(LoginResult.LOGIN_FAIL);
        }
      });
  }
}
