<mat-form-field>
  <mat-label>Vendedor Padrão</mat-label>
  <mat-select
    [ngModel]="defaultVendor"
    (selectionChange)="onVendorSelected($event)"
    [compareWith]="compareById"
  >
    <mat-option>Nenhum</mat-option>
    <mat-option *ngFor="let vendor of vendors | async" [value]="vendor">
      {{ vendor.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
