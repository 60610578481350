import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LayoutBackComponent } from './layout-back.component';
import { DefaultSharedModule } from '@eceos/default-shared';

@NgModule({
  imports: [CommonModule, DefaultSharedModule],
  declarations: [LayoutBackComponent],
  exports: [LayoutBackComponent]
})
export class LayoutBackModule {}
