import { Component, OnInit } from '@angular/core';
import { ReportFormat } from '@eceos/domain';
import { SettingsService } from '../../../core';

@Component({
  selector: 'app-default-print-format',
  templateUrl: './default-print-format.component.html',
  styleUrls: ['./default-print-format.component.scss']
})
export class DefaultPrintFormatComponent implements OnInit {
  readonly formats = [ReportFormat.PDF, ReportFormat.ESCPOS];

  constructor(private repository: SettingsService) {}

  ngOnInit() {}

  get defaultPrintFormat(): ReportFormat {
    return this.repository.defaultPrintFormat;
  }

  onSelected(format: ReportFormat) {
    this.repository.defaultPrintFormat = format;
  }

  compareById(obj1: ReportFormat, obj2: ReportFormat) {
    return obj1.name === obj2.name;
  }
}
