import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from './../../shared/shared.module';
import { DefaultSaleVendorComponent } from './default-sale-vendor/default-sale-vendor.component';
import { PrinterSettingsComponent } from './printer-settings/printer-settings.component';
import { SaleUiSettingsComponent } from './sale-ui-settings/sale-ui-settings.component';
import { SettingsComponent } from './settings.component';
import { SettingsRoutes } from './settings.routes';
import { WeighingMachineSettingsComponent } from './weighing-machine-settings/weighing-machine-settings.component';
import { DefaultHomeSettingsComponent } from './default-home-settings/default-home-settings.component';
import { DefaultPrintFormatComponent } from './default-print-format/default-print-format.component';

@NgModule({
  imports: [CommonModule, SharedModule, SettingsRoutes],
  declarations: [
    SettingsComponent,
    PrinterSettingsComponent,
    SaleUiSettingsComponent,
    DefaultSaleVendorComponent,
    WeighingMachineSettingsComponent,
    DefaultHomeSettingsComponent,
    DefaultPrintFormatComponent
  ]
})
export class SettingsModule {}
