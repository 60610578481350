<mat-form-field id="saleKey" thin>
  <mat-label>Nº Venda</mat-label>
  <input matInput readonly [value]="sale.key || 'Não Gerado'" />
</mat-form-field>

<mat-form-field id="saleGuestCard" thin *ngIf="hasGuestCards">
  <mat-label>Comanda</mat-label>
  <input
    #guestCardInput
    matInput
    [matAutocomplete]="guestCardAutocomplete"
    [value]="display(sale.guestCard)"
    [disabled]="blockGuestCard"
    (keyup)="guestCardKeypress($event.target)"
    (blur)="focusOut($event.target, sale.guestCard)"
    appBarcode
    (keyup.enter)="onSearchSubmit($event)"
  />
</mat-form-field>

<mat-autocomplete
  #guestCardAutocomplete="matAutocomplete"
  [displayWith]="display"
  (optionSelected)="onGuestCardSelected($event)"
>
  <mat-option *ngFor="let guestCard of guestCards | async" [value]="guestCard">
    {{ guestCard.name }}
    <small>- {{ guestCard.barcode }}</small>
  </mat-option>
</mat-autocomplete>

<mat-form-field id="saleVendor" thin *ngIf="hasVendors">
  <mat-label>Vendedor</mat-label>
  <input
    #vendorInput
    matInput
    [matAutocomplete]="vendorAutocomplete"
    [value]="display(sale.vendor)"
    (blur)="focusOut($event.target, sale.vendor)"
  />
</mat-form-field>

<mat-autocomplete
  #vendorAutocomplete="matAutocomplete"
  [displayWith]="display"
  (optionSelected)="onVendorSelected($event)"
>
  <mat-option *ngFor="let vendor of vendors" [value]="vendor">
    {{ vendor.name }}
  </mat-option>
</mat-autocomplete>

<mat-form-field id="saleClient" thin>
  <mat-label>Cliente</mat-label>
  <input
    #clientInput
    matInput
    [matAutocomplete]="clientAutocomplete"
    [value]="display(sale.client)"
    (keyup)="clientKeypress($event.target)"
    (blur)="focusOut($event.target, sale.client)"
  />
</mat-form-field>

<mat-autocomplete
  #clientAutocomplete="matAutocomplete"
  [displayWith]="display"
  (optionSelected)="onClientSelected($event)"
>
  <mat-option *ngFor="let client of clients | async" [value]="client">
    {{ client.name }}
    <small *ngIf="client?.cnp">{{ client?.cnp }}</small>
  </mat-option>
</mat-autocomplete>

<mat-form-field id="saleCnp" thin>
  <mat-label>CPF/CNPJ</mat-label>
  <input
    #cnpInput
    matInput
    [kzMask]="['999.999.999-99', '99.999.999/9999-99']"
    [value]="sale.cnp"
    (blur)="onCnpChange($event.target)"
    (keyup)="cnpValidate($event.target)"
    (invalidated)="notifyInvalidCnp(cnpInput)"
    (keydown.enter)="closeAllPanels()"
  />
</mat-form-field>
