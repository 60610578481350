import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Sale, SaleItem } from '@eceos/domain';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, finalize } from 'rxjs/operators';
import { WeightService } from '../../../../core/weighing-machines/weight.service';
import { OperatableSelectedEvent } from './../sale-operatables-list/sale-operatables-list.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SaleItemUpdateDialogComponent } from './sale-item-update-dialog/sale-item-update-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Hotkey, HotkeySet } from '@eceos/common-utils';
import { SaleItemListComponent } from '../sale-item-list/sale-item-list.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export class DialogMode {
  constructor(
    public readonly queryParam?: string
  ) { }

  get isDialogOpen(): boolean {
    return this?.queryParam === "open";
  }
}

@UntilDestroy()
@Component({
  selector: 'app-sale-details-mode-touch',
  templateUrl: './sale-details-mode-touch.component.html',
  styleUrls: ['./sale-details-mode-touch.component.scss']
})

export class SaleDetailsModeTouchComponent implements OnInit, OnDestroy {
  hotkeys = HotkeySet.of([
    Hotkey.key('f3')
      .description('Alterar entre busca/fita detalhe')
      .do(() => this.changeFocus()),
  ]);

  sale: Sale;

  currentItem: SaleItem = null;

  mobileInput = ''

  searchInputFocused = true;

  _dialogState = new DialogMode()

  showSaleDetails = false;

  @Output() weighing = new EventEmitter<boolean>();

  @Output() saleChange = new EventEmitter();

  @Output() isMobileScreen = new EventEmitter<boolean>();

  @ViewChild('search') searchInput: ElementRef;

  @ViewChild('side') side: ElementRef;

  @ViewChild('itemList') saleItemlistRef: SaleItemListComponent;

  @Output() enableParentHotkeys = new EventEmitter<boolean>();

  dialogUpdateItemRef: MatDialogRef<SaleItemUpdateDialogComponent>

  private queueChangeListener = new Subject();

  private queue: SaleItem[] = [];

  private ableToProcess = true;

  constructor(
    private weightService: WeightService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    protected location: Location
  ) {
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(qp => this.dialogState = new DialogMode(qp['saleInformations']));
  }

  ngOnDestroy(): void {
    this.hotkeys.disable();
  }

  ngOnInit() {
    this.queueChangeListener
      .asObservable()
      .pipe(debounceTime(100))
      .subscribe(() => this.processQueue());
  }

  @Input('paused')
  set paused(paused: boolean) {
    this.ableToProcess = !paused;
    this.queueChangeListener.next(null);
  }

  @Input('sale')
  set currentSale(sale: Sale) {
    if (this.sale !== sale) {
      this.sale = sale;
      this.currentItem = null;
    }
    this.publishSaleInformations();
  }

  @Input() isMobile: boolean;

  @Input('search')
  set search(search: string) {
    if (this.searchInput) {
      this.searchInput.nativeElement.value = search;
    }
  }

  private get dialogState() {
    return this._dialogState;
  }

  private set dialogState(state: DialogMode) {
    this._dialogState = state;
    this.publishSaleInformations();
  }

  publishSaleInformations() {
    if (this.sale) {
      if (this._dialogState.isDialogOpen) {
        this.showSaleDetails = true;
      } else {
        this.showSaleDetails = false;
      }
    }
  }

  processQueue(): void {
    if (this.ableToProcess) {
      let changed = false;
      while (this.queue.length > 0) {
        const item = this.queue.pop();
        this.sale.addItem(item);
        changed = true;
      }
      if (changed) {
        this.currentItem = this.sale.lastItem.value;
        this.notifyChange();
      }
    }
  }
  notifyChange(): void {
    this.saleChange.emit();
  }

  changeFocus() {
    if (!this.searchInputFocused || this.sale.totalAmount === 0) {
      this.requestSearchInputFocus();
    } else {
      this.requestSideFocus();
    }
  }

  requestSideFocus() {
    this.saleItemlistRef.requestFocus();
    this.searchInputFocused = false;
  }

  requestSearchInputFocus() {
    this.searchInput.nativeElement.focus();
    this.searchInputFocused = true;
  }

  onItemSelected(event: OperatableSelectedEvent) {
    const saleItem = new SaleItem(event.operatable, event.amount);
    if (saleItem.isWeightable && this.weightService.hasDefaultWeighingMachine) {
      this.weighing.emit(true);
      this.weightService
        .getWeight(saleItem)
        .pipe(
          finalize(() => this.weighing.emit(false)),
          catchError(err => {
            return of(saleItem);
          })
        )
        .subscribe(item => this.publishItem(item));
    } else {
      this.publishItem(saleItem);
    }
  }

  publishItem(saleItem: SaleItem) {
    this.queue.push(saleItem);
    this.queueChangeListener.next(null);
    setTimeout(() => this.requestSearchInputFocus(), 100);
  }

  updateSaleItemDialog(saleItem: SaleItem) {
    this.enableParentHotkeys.emit(false);
    this.dialogUpdateItemRef = this.dialog.open(SaleItemUpdateDialogComponent, {
      width: '450px',
      data: {
        sale: this.sale,
        saleItem: saleItem,
        weighingListener: (weighing: boolean) => this.weighingListener(weighing)
      }
    });
    this.dialogUpdateItemRef.afterClosed().subscribe(() => {
      this.enableParentHotkeys.emit(true);
      this.notifyChange();
      this.requestSearchInputFocus();
    })
  }

  footerDetailsNavigationClick() {
    if (!this.dialogState.isDialogOpen) {
      this.detailsNavigation();
    }
  }

  detailsNavigation() {
    if (!this.showSaleDetails) {
      this.router.navigate(['../'], {
        relativeTo: this.route,
        queryParams: { saleInformations: 'open' },
        replaceUrl: false
      })
      this.showSaleDetails = true;
    } else if (this.showSaleDetails) {
      this.backFromSaleDetails();
      this.showSaleDetails = false;
    }
  }

  backFromSaleDetails() {
    this.location.back();
  }

  enableHotkeys(enable: boolean) {
    this.enableParentHotkeys.emit(enable);
  }

  resetSearchInput() {
    this.searchInput.nativeElement.value = '';
    this.requestSearchInputFocus();
  }

  weighingListener(weighing: boolean) {
    this.weighing.emit(weighing);
  }

}
