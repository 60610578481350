<mat-list-item #saleItem class="sale-item" [class.has-stock]="isStockEnough" tabindex="0">
    <div class="sale-item-header" matLine>
        {{ number }}) {{ item.operatable?.barcode }} - {{ item.operatable?.name }}
    </div>
    <small class="values" matLine>
        <div class="alert" >
            <button (click)="onUnavalibleClick()" *ngIf="!isStockAvailable" class="btn" tabindex="-1">
                <mat-icon svgIcon="box-open"></mat-icon>
                <span class="alert-information-text">Estoque Indisponível</span>
            </button>
            <button (click)="onInsuficientClick()" *ngIf="!isStockEnough && isStockAvailable" class="btn" tabindex="-1">
                <mat-icon svgIcon="box-open"></mat-icon>
                <span class="alert-information-text">Estoque Insuficiente: {{item.operatable?.stock?.available}}</span>
                
            </button>
        </div>
        <div>
            {{ item.amount }} {{ item.operatable.defaultUnity.acronym }} x
            {{ item.unitaryPrice | currency: 'BRL':'symbol' }} =
            {{ item.total | currency: 'BRL':'symbol' }}
        </div>
    </small>
    <mat-divider></mat-divider>
</mat-list-item>