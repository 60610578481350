import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SaleItem } from '@eceos/domain';

@Component({
  selector: 'app-sale-item-line',
  templateUrl: './sale-item-line.component.html',
  styleUrls: ['./sale-item-line.component.scss']
})

export class SaleItemLineComponent implements OnInit {
  
  isStockAvailable = true;
  isStockEnough = true;
  _isWide = false;

  @ViewChild('saleItem', {read: ElementRef}) saleItemRef: ElementRef;

  @Input() number: number;
  
  private _item: SaleItem;
  
  constructor() {}

  get item(){
    return this._item
  }

  @Input()
  set item(value: SaleItem){
    if (value){
      this.isStockAvailable = value.isStockAvailable ;
      this.isStockEnough = value.isStockEnough;
    }
    this._item = value;
  }

  ngOnInit() {}
  
  onUnavalibleClick(){
    alert('Estoque Indisponível');
  }

  onInsuficientClick(){
    alert(`Estoque Insuficiente: ${this._item.operatable?.stock?.available}`);
  }

  requestFocus(){
    if(this.saleItemRef){
      this.saleItemRef.nativeElement.focus();
    }
  }
}
