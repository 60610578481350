import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../../../core/settings.service';
import { RemotePrinterSummary, RemotePrintersRepository } from '@eceos/domain';

@Component({
  selector: 'app-printer-settings',
  templateUrl: './printer-settings.component.html',
  styleUrls: ['./printer-settings.component.scss']
})
export class PrinterSettingsComponent implements OnInit {
  readonly printers: Observable<RemotePrinterSummary[]> = this.repository.list();

  constructor(
    private repository: RemotePrintersRepository,
    private settingsRepository: SettingsService
  ) {}

  ngOnInit() {}

  get defaultReceiptPrinter(): RemotePrinterSummary {
    return this.settingsRepository.defaultReceiptPrinter;
  }

  get printerRepository(): RemotePrintersRepository {
    return this.repository;
  }

  onPrinterSelected(value: RemotePrinterSummary) {
    this.settingsRepository.defaultReceiptPrinter = value;
  }

  compareById(obj1: RemotePrinterSummary, obj2: RemotePrinterSummary) {
    return obj1 === obj2 || (obj1 && obj2 && obj1.id === obj2.id);
  }
}
