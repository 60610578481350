import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Hotkey, HotkeySet } from '@eceos/common-utils';
import { GuestCardsRepository, GuestCardSummary } from '@eceos/domain';
import { Observable, Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

export class GuestCardSelectedEvent {
  constructor(public guestCard: GuestCardSummary) {}
}

@Component({
  selector: 'app-guest-cards-list',
  templateUrl: './guest-cards-list.component.html',
  styleUrls: ['./guest-cards-list.component.scss']
})
export class GuestCardsListComponent implements OnInit {
  hotkeys = HotkeySet.of([
    Hotkey.key('f5')
      .description('Atualizar relação de comandas')
      .do(() => this.refresh())
  ]);

  guestCards: GuestCardSummary[] = [];

  private querySubject = new Subject<string>();

  private currentQuery = '';

  @ViewChild('searchResults', { static: true }) resultsDiv: ElementRef;

  loading = false;

  constructor(private repository: GuestCardsRepository, private router: Router) {
    this.querySubject
      .asObservable()
      .pipe(
        debounceTime(500),
        switchMap((result: string) => {
          this.loading = true;
          return this.openOneGuestCardByBarcode(result);
        })
      )
      .subscribe(
        result => {
          this.guestCards = result;
          this.resultsDiv.nativeElement.scrollTop = 0;
          this.loading = false;
          this.openOneGuestCardByName();
        },
        e => {
          this.loading = false;
        }
      );

    this.querySubject.next('');
  }

  private openOneGuestCardByBarcode(result: string): Observable<GuestCardSummary[]> {
    return this.repository.list(result).pipe(
      tap(guestCards => {
        const guestCard = guestCards.find(g => g.barcode === result);
        if (guestCard) {
          this.onGuestCardClick(guestCard);
        }
      })
    );
  }

  private openOneGuestCardByName() {
    if (this.guestCards.length === 1) {
      this.onGuestCardClick(this.guestCards[0]);
    }
  }

  ngOnInit() {}

  onSearch(str: string) {
    this.currentQuery = str;
    this.querySubject.next(str);
  }

  onGuestCardClick(clicked: GuestCardSummary) {
    this.router.navigate(['/sales', clicked.currentSale ? clicked.currentSale.id : `new`], {
      queryParams: { guestCard: clicked.id }
    });
  }

  refresh(): void {
    this.querySubject.next(this.currentQuery);
  }
}
