<app-layout-menu [loading]="loading">
  <section class="center">
    <div class="search">
      <div class="search-icon">
        <mat-icon>search</mat-icon>
      </div>
      <input
        #search
        matInput
        autofocus
        autocomplete="off"
        placeholder="Pesquisar"
        appBarcode
        (keyup)="onSearch(search.value)"
      />
    </div>
  </section>

  <section class="actions">
    <button mat-icon-button (click)="refresh()">
      <mat-icon>cached</mat-icon>
    </button>
  </section>

  <div #searchResults class="search-results">
    <mat-card
      tabindex="0"
      [class.has-sale]="gc.currentSale"
      class="guest-card-card"
      *ngFor="let gc of guestCards"
      (click)="onGuestCardClick(gc)"
      (keydown.enter)="onGuestCardClick(gc)"
    >
      <mat-card-header>
        <section class="status">
          <span *ngIf="!gc.currentSale" class="free">Livre</span>
          <span *ngIf="gc.currentSale" class="notFree"
            >Ocupada a {{ gc.currentSale.date | amTimeAgo }}</span
          >
        </section>
        <mat-card-title>{{ gc.name }}</mat-card-title>
        <mat-card-subtitle>Cód.: {{ gc.barcode }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content *ngIf="gc.currentSale">
        <div>Total: {{ gc.currentSale.value | currency: 'BRL':'symbol' }}</div>
        <div *ngIf="gc.currentSale.client">Cliente: {{ gc.currentSale.client.name }}</div>
        <div *ngIf="gc.currentSale.vendor">Vendedor: {{ gc.currentSale.vendor.name }}</div>
      </mat-card-content>
    </mat-card>
  </div>
</app-layout-menu>
