<section class="breadcrumb" *ngIf="breadcrumb && !loading">
    <app-breadcrumb
        tabindex="-1"
        [breadcrumb]="breadcrumb"
        [loading]="loading"
        (itemClick)="onBreadcrumbClick($event)"
    ></app-breadcrumb>
</section>

<div #searchResults class="container" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300"
    [scrollWindow]="false" (scrolled)="onScrollDown($event)">

    <div *ngIf="loading" class="loading">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!loading">
        <section class="search-results">

            <mat-card tabindex="0" thinBorder class="operatable-card back" *ngIf="nodeParent?.parent"
                (click)="onBackCardClick(nodeParent.parent)" (keydown.enter)="onBackCardClick(nodeParent.parent)">
                <mat-icon>undo</mat-icon>
                Voltar
            </mat-card>

            <div class="nodes" thinBorder *ngFor="let node of nodes; trackBy: trackByNodeId">
                <app-operatable-tree-node-card [operatableNode]="node" (selectedNode)="onNodeClick($event)">
                </app-operatable-tree-node-card>
            </div>

        </section>
        <div *ngIf="!hasSearchResults && !loading" class="operatableNotFound">
            <h4>Nenhum item encontrado para seu critério de busca</h4>
        </div>
    </div>
</div>