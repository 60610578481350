<div class="deferredPayment">
  <div class="entranceCard installmentCard">
    <h3>Entrada</h3>
    <app-payment-table
      [paymentTable]="deferredPaymentForm"
      [toPay]="toPay"
      (change)="calculateParcelValue()"
    ></app-payment-table>
  </div>
  <div class="parcelsCard installmentCard">
    <h3>Parcelamento</h3>
    <app-payment-label-value
      label="Valor a parcelar"
      [value]="pending"
      (change)="calculateParcelValue()"
    ></app-payment-label-value>
    <section class="line">
      <mat-form-field thin>
        <mat-label>Política de Parcelamento</mat-label>
        <mat-select
          [(ngModel)]="installmentPolicy"
          (selectionChange)="calculateParcelValue()"
        >
          <mat-option *ngFor="let ip of installmentPolicies" [value]="ip">{{ ip.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>
    <section class="line">
      <mat-form-field thin>
        <mat-label>Nº Parcelas</mat-label>
        <input
          id="numberOfInstallments"
          #numberOfInstallments
          matInput
          type="number"
          [(ngModel)]="deferredPaymentForm.numberOfInstallments"
          [disabled]="!installmentPolicy"
          (keyup)="calculateParcelValue()"
        />
      </mat-form-field>
      <mat-form-field thin>
        <mat-label>1ª Parcela</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="deferredPaymentForm.firstExpiration"
          (change)="calculateParcelValue()"
          [disabled]="!installmentPolicy"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </section>
    <section class="line">
      <app-periodicities
        [(periodicity)]="deferredPaymentForm.periodicity"
        (change)="calculateParcelValue()"
        [disabled]="!installmentPolicy"
      ></app-periodicities>
      <mat-form-field thin>
        <input id="parcelValue" matInput currencyMask [(ngModel)]="parcelValue" disabled="true" />
      </mat-form-field>
    </section>
  </div>
</div>
