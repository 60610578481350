<mat-form-field>
  <mat-label>Tela inicial padrão</mat-label>
  <mat-select
    [ngModel]="value"
    (selectionChange)="onSelected($event)"
    [compareWith]="compareById"
  >
    <mat-option [value]="sales"> 
      Listagem de vendas pendentes
    </mat-option>
    <mat-option [value]="guestCards">
      Listagem de comandas
    </mat-option>
  </mat-select>
</mat-form-field>
