import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  InstallmentPolicySummary,
  InstallmentPoliciesRepository,
  DeferredPaymentForm
} from '@eceos/domain';

@Component({
  selector: 'app-deferred-payment',
  templateUrl: './deferred-payment.component.html',
  styleUrls: ['./deferred-payment.component.scss']
})
export class DeferredPaymentComponent implements OnInit {
  @Input() deferredPaymentForm: DeferredPaymentForm;
  @Input() toPay: number;

  @ViewChild('numberOfInstallments', { static: true })
  numberOfInstallmentsElement: ElementRef;

  parcelValue = 0;

  installmentPolicies: InstallmentPolicySummary[] = [];

  constructor(
    private installmentPoliciesRepository: InstallmentPoliciesRepository,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.installmentPoliciesRepository.list().subscribe(data => {
      this.installmentPolicies = data;
      this.calculateParcelValue();
    });
  }

  get pending(): number {
    return this.deferredPaymentForm ? this.deferredPaymentForm.getPendingPaid(this.toPay) : 0;
  }

  calculateParcelValue(): void {
    if (this.deferredPaymentForm && this.deferredPaymentForm.installmentPolicy) {
      this.installmentPoliciesRepository
        .calculateParcelValue(
          this.deferredPaymentForm.installmentPolicy,
          this.deferredPaymentForm.getParcelSimulator(this.toPay)
        )
        .subscribe(
          value => {
            this.parcelValue = value;
          },
          err => {
            this.deferredPaymentForm.numberOfInstallments = undefined;
            this.numberOfInstallmentsElement.nativeElement.value = '';
          }
        );
    }
  }

  get installmentPolicy(): InstallmentPolicySummary {
    if (
      this.deferredPaymentForm &&
      this.deferredPaymentForm.installmentPolicy &&
      this.installmentPolicies.length > 0
    ) {
      const installmentPolicy = this.installmentPolicies.find(
        ip => ip.id === this.deferredPaymentForm.installmentPolicy.id
      );
      return installmentPolicy;
    }
    return undefined;
  }

  set installmentPolicy(installmentPolicy: InstallmentPolicySummary) {
    this.deferredPaymentForm.installmentPolicy = installmentPolicy;
  }
}
