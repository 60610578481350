import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { SaleSummary } from '../sales/sale';

export class GuestCardSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public barcode = '',
    public name = '',
    public readonly currentSale: SaleSummary = null
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, { currentSale: null });
  }

  static fromJson(json: any): GuestCardSummary {
    return json ? SgvJson.from.simple(json, GuestCardSummary) : null;
  }
}
