import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Operatable } from './operatable';
import {
  SgvJson,
  ApiPath,
  ApiService,
  InterceptorConfig,
  RequestMetadata,
  RequestMetadataUtils
} from '@eceos/arch';
import { map } from 'rxjs/operators';

export class BarcodeResult {
  public readonly operatable: Operatable = null;
  public readonly amount = 0.0;
  toJson(): any {
    return SgvJson.to.simple(this, { operatable: this.operatable.toJson() });
  }

  static fromJson(json: any): BarcodeResult {
    return SgvJson.from.simple(json, BarcodeResult, {
      operatable: Operatable.fromJson(json.operatable)
    });
  }
}

@Injectable({ providedIn: 'root' })
export class BarcodeService {
  private path: ApiPath;

  private metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO };

  constructor(api: ApiService) {
    this.path = api.root.path('/barcodes/operatable/');
  }

  findByBarcode(
    barcode: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<BarcodeResult> {
    return this.path
      .path(barcode)
      .getJson({ metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map(r => BarcodeResult.fromJson(r)));
  }
}
