import { PaybackTable } from './payback-table';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FinancialDocument,
  PaymentDocumentValue,
  FinancialDocumentsRepository
} from '@eceos/domain';

@Component({
  selector: 'app-payback-table',
  templateUrl: './payback-table.component.html',
  styleUrls: ['./payback-table.component.scss']
})
export class PaybackTableComponent implements OnInit {
  @Input() paybackTable: PaybackTable = null;

  @Input() toPay = 0;

  documents: FinancialDocument[] = [];

  @Output() change = new EventEmitter();

  constructor(private financialDocumentsRepository: FinancialDocumentsRepository) {}

  ngOnInit() {
    if (this.paybackTable) {
      this.financialDocumentsRepository.allowsPayback().subscribe(data => {
        this.documents = data;
        this.onPaibackValueChange();
      });
    }
  }

  getAvailableDocuments(documentValue?: PaymentDocumentValue): FinancialDocument[] {
    const paybackTableDocuments = this.paybackTable
      .getChange()
      .filter(e => e !== documentValue)
      .map(e => e.document);
    return this.documents.filter(d => !paybackTableDocuments.some(pbd => pbd === d));
  }

  get pending(): number {
    return this.paybackTable ? this.paybackTable.getPendingChange(this.toPay) : 0;
  }

  public onPaibackValueChange(pv?: PaymentDocumentValue) {
    const zeroedPv = this.paybackTable.getChange().filter(p => p.value === 0);
    if (zeroedPv.length === 0 && this.pending > 0) {
      const availableDocument = this.getAvailableDocuments()[0];
      if (availableDocument) {
        this.paybackTable.newChange().document = availableDocument;
      }
    } else if (zeroedPv.length > 1) {
      this.paybackTable
        .getChange()
        .filter(p => p.document !== pv.document && p.value === 0)
        .forEach(p => {
          this.paybackTable.removeChange(p);
        });
    }
    this.change.emit(this.paybackTable);
  }

  public onCompleteClicked(pv: PaymentDocumentValue) {
    this.paybackTable.getChange().forEach(p => {
      if (p.document !== pv.document) {
        this.paybackTable.removeChange(p);
      } else {
        p.value = this.pending;
      }
    });
    this.change.emit(this.paybackTable);
  }

  public getPlaceHolder(index: number): string {
    if (this.paybackTable.getChange().length > 1) {
      return 'Troco ' + (index + 1);
    }
    return 'Troco';
  }
}
