<mat-form-field>
  <mat-label>Modo padrão de seleção de produto</mat-label>
  <mat-select
    [ngModel]="defaultProductSelectorMode"
    (selectionChange)="onProductModeSelected($event)"
    [compareWith]="compareById"
  >
    <mat-option [value]="simpleMode">
      Modo Simples (Leitura de Cod. Barras)
    </mat-option>
    <mat-option [value]="touchMode">
      Modo Touch (Seleção de produtos)
    </mat-option>
  </mat-select>
</mat-form-field>
