import { MatSelectChange } from '@angular/material/select';
import { ProductSelectorMode } from '../../sales/sale-details/product-selector-mode';
import { SettingsService, PdvHome } from '../../../core/settings.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-home-settings',
  templateUrl: './default-home-settings.component.html',
  styleUrls: ['./default-home-settings.component.scss']
})
export class DefaultHomeSettingsComponent implements OnInit {
  constructor(private repository: SettingsService) {}

  ngOnInit() {}

  get value(): PdvHome {
    return this.repository.defaultHome;
  }

  onSelected(event: MatSelectChange) {
    this.repository.defaultHome = event.value;
  }

  compareById(obj1: PdvHome, obj2: PdvHome) {
    return obj1 === obj2;
  }

  get sales(): PdvHome {
    return PdvHome.SALES;
  }

  get guestCards(): PdvHome {
    return PdvHome.GUEST_CARDS;
  }
}
