import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { LoginResult, LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private loginService: LoginService, private snackbar: MatSnackBar) {}

  ngOnInit() {}

  submitLogin(identifier: string, username: string, password: string): void {
    try {
      this.loginService
        .validateLogin(identifier, username, password)
        .pipe(
          map(result => {
            switch (result) {
              case LoginResult.EMPTY_PDV_KEY:
                return 'Informe o Identificador do PDV';
              case LoginResult.EMPTY_USERNAME || LoginResult.EMPTY_PASSWORD:
                return 'Informe seus dados de acesso';
              case LoginResult.INVALID_USERNAME_FORMAT:
                return 'Informe o usuário no padrão usuario@empresa';
              case LoginResult.LOGIN_FAIL:
                return 'Falha ao efetuar o login';
              case LoginResult.LOGIN_SUCCESS:
                return 'Login efetuado com sucesso';
              default:
                return '';
            }
          })
        )
        .subscribe(message => {
          this.snackbar.open(message || 'Falha ao efetuar o login', null, { duration: 2000 });
        });
    } catch (e) {
      this.snackbar.open(e.message || 'Falha ao efetuar o login', null, { duration: 2000 });
      console.error(e);
    }
  }
}
