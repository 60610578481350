import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared';
import { SaleDeliveryComponent } from './sale-delivery.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SaleDeliveryComponent]
})
export class SaleDeliveryModule {}
