<mat-form-field>
  <mat-label>Formato de Impressão</mat-label>
  <mat-select
    [ngModel]="defaultPrintFormat"
    (selectionChange)="onSelected($event.value)"
    [compareWith]="compareById"
  >
    <mat-option *ngFor="let format of formats" [value]="format">
      {{ format.description }}</mat-option
    >
  </mat-select>
</mat-form-field>
