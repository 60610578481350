import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SearchBoxComponent } from '@eceos/common-components';
import { Hotkey, HotkeySet } from '@eceos/common-utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-layout-back',
  templateUrl: './layout-back.component.html',
  styleUrls: ['./layout-back.component.scss']
})
export class LayoutBackComponent {
  @Input() backPath: string;
  @Input() loading = false;
  @Input() showSearch = false;
  @Input() beforeGoBack: () => Promise<void>;
  @Output() search = new EventEmitter<string>();

  private _hotkeys: HotkeySet;
  private _searchBox: SearchBoxComponent;

  searchPlaceholder = 'Pesquisar';
  isSearchOpened$: Observable<boolean>;
  isSearchOpened = false;
  constructor(private location: Location, private router: Router) { }

  @ViewChild(SearchBoxComponent)
  set searchBox(searchBox: SearchBoxComponent) {
    if (this._searchBox !== searchBox) {
      this._searchBox = searchBox;
      this.publishFromSearchBox();
    }
  }

  @Input() set hotkeys(value: HotkeySet) {
    if (value !== this._hotkeys) {
      this._hotkeys = value
        ? value.add(
          Hotkey.key('esc')
            .description('Voltar para tela anterior')
            .do(() => this.goBack())
        )
        : value;
    }
  }

  get searchBox(): SearchBoxComponent {
    return this._searchBox;
  }

  async goBack() {
    if (this.beforeGoBack) {
      await this.beforeGoBack();
    }
    if (!this.backPath) {
      this.location.back();
    } else {
      this.router.navigate([this.backPath]);
    }
  }

  onSearchChange(text: string) {
    if (this.showSearch) {
      this.search.emit(text);
    }
  }

  onSearchClear() {
    if (this.showSearch) {
      this.search.emit('');
    }
  }

  private publishFromSearchBox() {
    if (this.showSearch && this.searchBox) {
      this.isSearchOpened$ = this.searchBox.isSearchVisible$
      this.isSearchOpened$.subscribe(value => {
        this.isSearchOpened = value;
      })
    }
  }

}
