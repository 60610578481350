import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Hotkey, HotkeySet, navigateIf } from '@eceos/common-utils';
import {
  CashPaymentForm,
  CurrentPdvProfileService,
  DeferredPaymentForm,
  PdvProfile,
  Sale,
  SalesRepository
} from '@eceos/domain';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-sale-payments',
  templateUrl: './sale-payments.component.html',
  styleUrls: ['./sale-payments.component.scss']
})
export class SalePaymentsComponent implements OnInit, OnDestroy {
  hotkeys = HotkeySet.of([
    Hotkey.key('f3')
      .description('Mudar para pagamento à vista')
      .do(() => this.sale.asCashPaymentForm()),
    Hotkey.key('f4')
      .description('Mudar para pagamento à prazo')
      .do(() => this.sale.asDeferredPaymentForm()),
    Hotkey.key('f8')
      .key('ctrl+enter')
      .description('Concluir e navegar para o pagamento')
      .do(() => this.submitAndNext())
  ]);

  sale: Sale = null;

  profile: PdvProfile;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private repository: SalesRepository,
    private currentPdvProfileService: CurrentPdvProfileService,
    public snackBar: MatSnackBar
  ) {
    this.currentPdvProfileService.profile$.subscribe(profile => (this.profile = profile));
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap(params => {
          const id = params.get('id');
          return this.repository.find(id);
        }),
        navigateIf(
          (sale: Sale) => sale.isFinalized(),
          (sale: Sale) => this.router.navigate(['/sales', sale.id, 'postFinish'])
        )
      )
      .subscribe((sale: Sale) => (this.sale = sale));
    this.hotkeys.enable();
  }

  ngOnDestroy() {
    this.hotkeys.disable();
  }

  get isWithDelivery(): boolean {
    return this.sale.isWithDeliveryData() || (this.profile && this.profile.withDelivery);
  }

  nextEnabled(): boolean {
    if (this.sale && this.sale.payment instanceof CashPaymentForm) {
      const cp: CashPaymentForm = <CashPaymentForm>this.sale.payment;

      return (cp.getTotalPaid() - cp.getTotalChange()).toFixed(2) === this.sale.total.toFixed(2);
    } else if (this.sale && this.sale.payment instanceof DeferredPaymentForm) {
      const dp: DeferredPaymentForm = <DeferredPaymentForm>this.sale.payment;
      return (
        Boolean(dp.installmentPolicy) &&
        Boolean(dp.firstExpiration) &&
        Boolean(dp.numberOfInstallments) &&
        Boolean(dp.periodicity)
      );
    }
    return false;
  }

  submitAndNext(): void {
    if (this.nextEnabled()) {
      this.repository.update(this.sale).subscribe(s => {
        this.sale = s;
        this.repository
          .finalize(this.sale)
          .subscribe(r => this.router.navigate(['/sales', this.sale.id, 'postFinish']));
      });
    } else {
      this.snackBar.open('Forma de pagamento inválida.', null, { duration: 2000 });
    }
  }
}
