import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { PageData } from '@eceos/arch';
import { Operatable, OperatablesRepository } from '@eceos/domain';
import { debounceTime, Subject, switchMap } from 'rxjs';

@Component({
  selector: 'app-operatable-dialog',
  templateUrl: './operatable-dialog.component.html',
  styleUrls: ['./operatable-dialog.component.scss'],
})
export class OperatableDialogComponent implements OnInit {

  private querySubject = new Subject<string>();

  private page = new PageData(0, 25);

  private currentPage = this.page;

  focusInput: boolean;

  _currentQuery: string;

  operatables: Operatable[] = [];

  selectedOperatable: Operatable;

  loading = false;

  @ViewChild('search') searchInput: ElementRef;

  @ViewChild('searchResults') resultsDiv: ElementRef;

  @ViewChild('opList') operatableList: MatSelectionList;

  constructor(
    private dialogRef: MatDialogRef<OperatableDialogComponent>,
    private repository: OperatablesRepository,

  ) {
    this.querySubject
      .asObservable()
      .pipe(
        debounceTime(500),
        switchMap((r: string) => repository.page((this.currentPage = this.page), r))
      )
      .subscribe(r => {
        this.operatables = r;
        this.loading = false;
        if (this.focusInput) {
          setTimeout(() => this.operatableList.focus(), 100);
          this.focusInput = false;
        }
        this.resultsDiv.nativeElement.scrollTop = 0;
      });

    this.querySubject.next('');

  }

  ngOnInit(): void {
  }

  @Input()
  set currentQuery(str: string) {
    this._currentQuery = str;
    this.loading = true;
    this.querySubject.next(this._currentQuery);
  }

  get currentQuery() {
    return this._currentQuery;
  }

  onScrollDown(event: any) {
    this.currentPage = this.currentPage.next;
    this.repository
      .page(this.currentPage, this.currentQuery)
      .subscribe(data => data.forEach(d => this.operatables.push(d)));
  }

  onOperatableSelected() {
    if (this.selectedOperatable) {
      this.dialogRef.close(this.selectedOperatable);
    }
    else {
      this.querySubject.next(this.currentQuery);
      this.operatableList.focus();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  trackById(i, entity): string {
    return entity.id;
  }

}
