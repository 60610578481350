import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { InterceptorConfig, RequestMetadata } from '@eceos/arch';
import { BlobHandler, Hotkey, HotkeySet } from '@eceos/common-utils';
import {
  BaseNFeSummary,
  FinancialMovementsRepository,
  NFCeSummary,
  NfesRepository,
  NFeSummary,
  Sale,
  SalesRepository
} from '@eceos/domain';
import { EMPTY, lastValueFrom, Observable, Observer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SettingsService } from '../../../core/settings.service';
import { LayoutBackComponent } from '../../../shared/layout/layout-back/layout-back.component';
import { MailInputDialogComponent } from './mail-input-dialog/mail-input-dialog.component';

@Component({
  selector: 'app-sale-post-finish',
  templateUrl: './sale-post-finish.component.html',
  styleUrls: ['./sale-post-finish.component.scss']
})
export class SalePostFinishComponent implements OnInit, OnDestroy {
  hotkeys = HotkeySet.of([
    Hotkey.key('f2')
      .description('Imprimir comprovante de venda')
      .do(() => this.printSale()),
    Hotkey.key('f3')
      .description('Transmitir/Imprimir NFCe')
      .do(() => (this.hasAuthorizedNF ? this.printDanfe() : this.emitNFCe())),
    Hotkey.key('f4')
      .description('Enviar NF-e/NFC-e por e-mail')
      .do(() =>
        this.hasAuthorizedNF
          ? this.emailDanfe()
          : this.showMessage('Transmita a NF-e/NFC-e antes de enviar por email!')
      ),
    Hotkey.key('f5')
      .description('Transmitir/Imprimir NF-e')
      .do(() => (this.hasAuthorizedNF ? this.printDanfe() : this.emitNFe())),
    Hotkey.key('f6')
      .description('Imprimir Movimentação Financeira')
      .do(() => this.printFinancialMovement()),
    Hotkey.key('f7')
      .description('Visualizar A4 Movimentação Financeira')
      .do(() => this.onViewFinancialMovementPDF()),
    Hotkey.key('f8')
      .description('Abrir nova venda')
      .do(() => this.openNewSale()),
    Hotkey.key('f9')
      .description('Imprimir Ficha de Consumo')
      .do(() => this.onSaleItemTicketPrint())
  ]);

  @ViewChild(LayoutBackComponent, { static: true }) layoutBack: LayoutBackComponent;

  sale: Sale;

  nfe: BaseNFeSummary;

  creatingNFe = false;

  transmittingNFe = false;

  readonly pdvHome = this.settingsRepository.defaultHome;

  private readonly VIEW_SALE_PATH = 'https://eceos.cloud/public/sale/';

  private readonly VIEW_NFE_PATH = 'https://eceos.cloud/public/nfe/danfe/';

  private readonly DOWNLOAD_NFE_XML_PATH = 'https://eceos.cloud/public/nfe/';

  private defaultRequestMetadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private salesRepository: SalesRepository,
    private settingsRepository: SettingsService,
    private nfesRepository: NfesRepository,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    private financialMovementsRepository: FinancialMovementsRepository
  ) {}

  get canTrasmitNFe(): boolean {
    return Boolean(this.sale) && Boolean(this.sale.client);
  }

  get isSyncNFe() {
    return this.creatingNFe || this.transmittingNFe;
  }

  get hasAuthorizedNF(): boolean {
    return Boolean(this.nfe) && this.nfe.authorized;
  }

  get hasDefaultPrinter(): boolean {
    return Boolean(this.settingsRepository.defaultReceiptPrinter);
  }

  get viewSaleCuponPath(): string {
    return `${this.VIEW_SALE_PATH}${this.sale.id}.pdf?mode=SRC`;
  }

  get viewSaleA4Path(): string {
    return `${this.VIEW_SALE_PATH}${this.sale.id}.pdf?mode=SRA4`;
  }

  get viewNFCePath(): string {
    return `${this.VIEW_NFE_PATH}${this.nfe.id}.pdf`;
  }

  get viewNFePath(): string {
    return `${this.VIEW_NFE_PATH}${this.nfe.id}.pdf`;
  }

  get downloadNFeXmlPath(): string {
    return `${this.DOWNLOAD_NFE_XML_PATH}${this.nfe.id}.xml`;
  }

  get isNFe(): boolean {
    return this.nfe instanceof NFeSummary;
  }

  get isNFCe(): boolean {
    return this.nfe instanceof NFCeSummary;
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap(params => {
          const id = params.get('id');
          if ('new' === id) {
            return new Observable(ob => ob.next(new Sale()));
          } else {
            return this.salesRepository.findSummary(id);
          }
        })
      )
      .subscribe(
        (sale: Sale) => {
          this.sale = sale;
          try {
            lastValueFrom(this.checkIfNFeExists());
          } catch (e) {}
        },
        e => {
          console.error(e);
          this.layoutBack.goBack();
        }
      );
    this.hotkeys.enable();
  }

  ngOnDestroy() {
    this.hotkeys.disable();
  }

  printSale() {
    this.handlePrint(() =>
      this.salesRepository.printTo(
        this.sale,
        this.settingsRepository.defaultReceiptPrinter,
        this.settingsRepository.defaultPrintFormat,
        this.defaultRequestMetadata
      )
    );
  }

  emitNFe() {
    this.emit(FiscalDocumentModel.NFE);
  }

  emitNFCe() {
    this.emit(FiscalDocumentModel.NFCE);
  }

  printDanfe() {
    if (this.hasAuthorizedNF) {
      this.handlePrint(() =>
        this.nfesRepository.printTo(
          this.nfe,
          this.settingsRepository.defaultReceiptPrinter,
          this.settingsRepository.defaultPrintFormat,
          this.defaultRequestMetadata
        )
      );
    }
  }

  emailDanfe() {
    if (this.hasAuthorizedNF) {
      const dialogRef = this.dialog.open(MailInputDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.nfesRepository
            .mailTo(this.nfe, result, this.defaultRequestMetadata)
            .subscribe(
              r => this.showMessage(`Email enviado para ${result}`),
              e => this.showError(e, 'Erro ao enviar email!')
            );
        }
      });
    }
  }

  printFinancialMovement() {
    this.handlePrint(() =>
      this.financialMovementsRepository.printReportPDFFromSale(
        this.sale,
        this.settingsRepository.defaultReceiptPrinter
      )
    );
  }

  onViewFinancialMovementPDF() {
    this.financialMovementsRepository
      .getReportPDFFromSale(this.sale)
      .subscribe(
        blob => new BlobHandler(blob).open(),
        err => this.showError(err, 'Falha ao gerar PDF.')
      );
  }

  onSaleItemTicketPrint() {
    this.handlePrint(() =>
      this.salesRepository.printItemTicketTo(
        this.sale,
        this.settingsRepository.defaultReceiptPrinter,
        this.defaultRequestMetadata
      )
    );
  }

  openNewSale() {
    this.router.navigate(['/sales', 'new']);
  }

  navigateTo(path: string) {
    window.open(path);
  }

  private checkIfNFeExists(): Observable<BaseNFeSummary> {
    if (this.sale) {
      return new Observable((ob: Observer<BaseNFeSummary>) => {
        this.nfesRepository.findSummaryBySale(this.sale, this.defaultRequestMetadata).subscribe(
          (nfe: NFeSummary) => {
            this.nfe = nfe;
            ob.next(this.nfe);
          },
          e => {
            console.error(e);
            ob.error(e);
          }
        );
      });
    }
    return EMPTY;
  }

  private transmitNFe() {
    if (this.nfe) {
      this.transmittingNFe = true;
      this.nfesRepository.transmit(this.nfe, this.defaultRequestMetadata).subscribe(
        r => {
          if (!r.isSuccess) {
            this.showMessage(
              'Não foi possível transmitir a nota pelo PDV, acesse https://eceos.cloud/app/sales/list.jsf para gerar'
            );
          }
          this.checkIfNFeExists().subscribe(nfe => {
            if (!this.hasAuthorizedNF) {
              this.showMessage(
                'Nota não autorizada. Acesse https://eceos.cloud/app/fiscal/nfes/list.jsf para verificar o motivo'
              );
            }
          });
        },
        e => {
          const errorMsg =
            e && e.error
              ? e.error.map(err => err.message).join('. ')
              : 'Erro ao transmitir.  Acesse https://eceos.cloud/app/fiscal/nfes/list.jsf para verificar o motivo.';
          this.showError(e, errorMsg, 5000);
          this.transmittingNFe = false;
        },
        () => (this.transmittingNFe = false)
      );
    }
  }
  private createAndTransmit(model: FiscalDocumentModel) {
    if (!this.nfe) {
      this.creatingNFe = true;
      this.nfesRepository.createFromSale(this.sale, model, this.defaultRequestMetadata).subscribe(
        nfe => {
          this.nfe = nfe;
          this.transmitNFe();
        },
        e => {
          this.showError(
            e,
            'Não foi possível gerar a nota pelo PDV, acesse https://eceos.cloud/app/sales/list.jsf para gerar'
          );
          this.creatingNFe = false;
        },
        () => (this.creatingNFe = false)
      );
    }
  }

  private emit(model: FiscalDocumentModel) {
    if (!this.isSyncNFe) {
      if (!this.hasAuthorizedNF) {
        if (this.nfe) {
          this.transmitNFe();
        } else {
          this.createAndTransmit(model);
        }
      }
    } else {
      this.showMessage('Em sincronização com o servidor');
    }
  }

  private handlePrint(printTo: () => Observable<Response>) {
    if (this.hasDefaultPrinter) {
      printTo().subscribe(
        r => this.showMessage('Impressão enviada com sucesso'),
        e => this.showError(e, 'Erro ao enviar impressão')
      );
    } else {
      this.showMessage('Impressora padrão não encontrada');
    }
  }

  private showMessage(message: string, duration: number = 2000) {
    this.snackbar.open(message, null, { duration: duration });
  }

  private showError(e: Error, message: string, duration: number = 2000) {
    if (e) {
      console.log(e);
    }
    this.showMessage(message, duration);
  }
}

enum FiscalDocumentModel {
  NFE = 'nfe',
  NFCE = 'nfce'
}
