import { Addition, NoAddition, PercentAddition, ValueAddition } from '../sales/addition';
import {
  PaymentDiscount,
  PaymentPercentDiscount,
  PaymentValueDiscount
} from '../sales/payment-discount';
import { CashPaymentForm, DeferredPaymentForm, PaymentForm } from '../sales/payment-form';
import { OutbondOperationItem } from './outbound-operation-item';

export abstract class OutboundOperation {
  public items: OutbondOperationItem[] = [];

  public payment: PaymentForm = null;

  public discount: PaymentDiscount = new PaymentValueDiscount();

  public addition: Addition = new NoAddition();

  abstract get totalFreight(): number;

  get totalAmount(): number {
    return this.items
      .map(it => it.amount)
      .filter(it => !isNaN(it))
      .reduce((vAnt, vAt) => vAnt + vAt, 0);
  }

  get totalOfItems(): number {
    return this.items
      .map(it => it.total)
      .filter(it => !isNaN(it))
      .reduce((vAnt, vAt) => vAnt + vAt, 0);
  }

  get totalOfItemsWithAddition(): number {
    return this.addition.applyOn(this.totalOfItems);
  }

  get totalAppliedCoupons(): number {
    return 0;
  }

  get totalPriceWithoutFreightAndDiscountAndAddition(): number {
    return this.totalOfItems - this.totalAppliedCoupons;
  }

  get totalPriceWithoutFreightAndDiscount(): number {
    return this.addition.applyOn(this.totalPriceWithoutFreightAndDiscountAndAddition);
  }

  get totalPriceWithoutFreight(): number {
    return this.discount.applyOn(this.totalPriceWithoutFreightAndDiscount);
  }

  get totalPriceWithoutDiscount(): number {
    return this.totalPriceWithoutFreightAndDiscount + this.totalFreight;
  }

  get totalDiscountOfItems(): number {
    return (
      this.discount.getValue(this.totalPriceWithoutFreightAndDiscount) +
      this.items
        .map(it => it.discountValue)
        .filter(it => !isNaN(it))
        .reduce((vAnt, vAt) => vAnt + vAt, 0)
    );
  }

  get totalOfAddition(): number {
    return this.addition.get(this.totalPriceWithoutFreightAndDiscountAndAddition);
  }

  get total(): number {
    return this.totalPriceWithoutFreight + this.totalFreight;
  }

  addItem(item: OutbondOperationItem): void {
    this.items.push(item);
  }

  removeItem(item: OutbondOperationItem) {
    const i = this.items.indexOf(item);
    if (i >= 0) {
      this.items.splice(i, 1);
    }
  }

  asCashPaymentForm(): void {
    if (!(this.payment instanceof CashPaymentForm)) {
      this.payment = new CashPaymentForm();
    }
  }

  asDeferredPaymentForm(): void {
    if (!(this.payment instanceof DeferredPaymentForm)) {
      this.payment = new DeferredPaymentForm();
    }
  }

  isCashPaymentForm(): boolean {
    return this.payment instanceof CashPaymentForm;
  }

  isDeferredPaymentForm(): boolean {
    return this.payment instanceof DeferredPaymentForm;
  }

  asPaymentValueDiscount(value: number = 0): void {
    this.discount = new PaymentValueDiscount(value);
  }

  asPaymentPercentDiscount(value: number = 0): void {
    this.discount = new PaymentPercentDiscount(value);
  }

  isPaymentValueDiscount(): boolean {
    return this.discount instanceof PaymentValueDiscount;
  }

  isPaymentPercentDiscount(): boolean {
    return this.discount instanceof PaymentPercentDiscount;
  }

  asValueAddition(value: number = 0): void {
    this.addition = new ValueAddition(value);
  }

  asPercentAddition(value: number = 0): void {
    this.addition = new PercentAddition(value);
  }

  isValueAddition(): boolean {
    return this.addition instanceof ValueAddition;
  }

  isPercentAddition(): boolean {
    return this.addition instanceof PercentAddition;
  }
}
