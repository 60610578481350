import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FinancialDocument, PaymentDocumentValue } from '@eceos/domain';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-payment-document-value',
  templateUrl: './payment-document-value.component.html',
  styleUrls: ['./payment-document-value.component.scss']
})
export class PaymentDocumentValueComponent implements OnInit {
  @Input() documentValue: PaymentDocumentValue;

  @Input() documents: FinancialDocument[];

  @Input() documentPlaceHolder: string;

  @Output() documentChange = new EventEmitter();

  @Output() valueChange = new EventEmitter<PaymentDocumentValue>();

  @Output() completeClicked = new EventEmitter();

  valueChangeSubject = new Subject<PaymentDocumentValue>();

  constructor() {}

  ngOnInit() {
    this.valueChangeSubject.pipe(debounceTime(500)).subscribe(v => this.valueChange.emit(v));
  }

  public get document() {
    if (this.documentValue) {
      return this.documents.find(fd => fd.id === this.documentValue.document.id);
    }
    return null;
  }

  public set document(document: FinancialDocument) {
    if (this.documentValue) {
      this.documentValue.document = document;
      this.documentChange.emit(this.documentValue);
    }
  }

  public get value() {
    if (this.documentValue) {
      return this.documentValue.value;
    }
    return null;
  }

  public set value(value: number) {
    if (this.documentValue) {
      this.documentValue.value = value;
      this.valueChangeSubject.next(this.documentValue);
    }
  }

  public onCompleteClicked(): void {
    this.completeClicked.emit(this.documentValue);
  }
}
