import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WeighingMachineService } from './weighing-machine.service';
import { SaleItem } from '@eceos/domain';
import { SettingsService } from '../settings.service';

@Injectable({providedIn:'root'})
export class WeightService { 
  constructor(
    private weighingMachineService: WeighingMachineService,
    private settingsService: SettingsService
  ) {}

  getWeight(item: SaleItem): Observable<SaleItem> {
    if (this.hasDefaultWeighingMachine) {
      if (item.isWeightable) {
        return this.weighingMachineService
          .getWeight(this.settingsService.defaultWeighingMachine.id)
          .pipe(
            map(value => {
              item.amount = value;
              return item;
            }),
            catchError(_ => throwError('Erro ao obter peso!'))
          );
      } else {
        return throwError('Item inválido para pesagem!');
      }
    } else {
      return throwError(`Balança padrão não encontrada!`);
    }
  }

  get hasDefaultWeighingMachine(): boolean {
    return Boolean(this.settingsService.defaultWeighingMachine);
  }
}
