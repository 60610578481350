import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GuestCardsRepository } from '@eceos/domain';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  hasGuestCards = false;

  constructor(private guestCardsRepository: GuestCardsRepository) {
    this.guestCardsRepository.count().subscribe(count => (this.hasGuestCards = count > 0));
  }

  ngOnInit() {}

  logout() {
    localStorage.clear();
    document.location.reload();
  }
}
