<mat-nav-list>
  <a mat-list-item routerLink="/sales/">
    <mat-icon mat-list-icon>list</mat-icon>
    <h4 matLine>Lista de Vendas</h4>
  </a>
  <a *ngIf="hasGuestCards" mat-list-item routerLink="/guestCards/">
    <mat-icon mat-list-icon>view_module</mat-icon>
    <h4 matLine>Comandas</h4>
  </a>
  <a mat-list-item routerLink="/settings/">
    <mat-icon mat-list-icon>settings</mat-icon>
    <h4 matLine>Configurações</h4>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item (click)="logout()">
    <mat-icon mat-list-icon>exit_to_app</mat-icon>
    <h4 matLine>Logout</h4>
  </a>
</mat-nav-list>
