import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageData } from '@eceos/arch';
import { Breadcrumb, BreadcrumbItem } from '@eceos/common-components';
import { NodeParent, NodeProduct, NodeProductCategory, NodeService, NodeServiceCategory, NodeTypeProduct, NodeTypeService, Operatable, OperatablesRepository, OperatableTreeNode, OperatableTreeNodeRepository, ProductsRepository } from '@eceos/domain';
import { ProductByLotsSummary, SimpleProductSummary, VariableProductSummary } from 'libs/domain/src/lib/products/product-summary';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';

export class OperatableSelectedEvent {
  constructor(public operatable: Operatable, public amount = 1) { }
}

@Component({
  selector: 'app-sale-operatable-tree',
  templateUrl: './sale-operatable-tree.component.html',
  styleUrls: ['./sale-operatable-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaleOperatableTreeComponent implements OnInit {

  nodes: OperatableTreeNode[] = [];

  hasSearchResults: boolean;

  currentNodeId = '';

  nodeParent: OperatableTreeNode;

  breadcrumb: Breadcrumb = new Breadcrumb();

  operatable: Operatable;

  dialogRef: MatDialogRef<ProductDialogComponent>

  loading = false;

  private querySubject = new Subject<{ query: string, currentNodeId: string }>();

  private page = new PageData(0, 25);

  private currentPage = this.page;

  private _query = '';

  @Output() enableParentHotkeys = new EventEmitter<boolean>();

  @Output() itemSelected = new EventEmitter<OperatableSelectedEvent>();

  @ViewChild('searchResults', { static: true }) resultsDiv: ElementRef;

  @Output() resetSearchInput = new EventEmitter<boolean>();

  constructor(
    private treeNodeRepository: OperatableTreeNodeRepository,
    private operatableRepository: OperatablesRepository,
    private dialog: MatDialog,
    private productRepository: ProductsRepository,
    private ref: ElementRef,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.querySubject
      .asObservable()
      .pipe(
        debounceTime(500),
        switchMap(({ query, currentNodeId }) => treeNodeRepository.openNode((this.currentPage = this.page), query, currentNodeId))
      )
      .subscribe(r => {
        this.nodes = r;
        this.selectNodeParent();
        this.loading = false;
        this.hasSearchResults = this.nodes.length > 0;
        this.resultsDiv.nativeElement.scrollTop = 0;
        this.changeDetector.markForCheck();
      });

    this.querySubject.next({ query: '', currentNodeId: this.currentNodeId });
  }

  ngOnInit(): void { }

  @Input('query')
  set query(str: string) {
    this._query = str;
    this.loading = true;
    this.querySubject.next({ query: str, currentNodeId: this.currentNodeId });
  }

  @Input() refreshCategoryOnSelectItem = true;

  private navigateToCategory() {
    this.breadcrumb.resetBreadcrumb();
    this.loading = true;
    this.nodes = []
    this.querySubject.next({ query: this._query, currentNodeId: this.currentNodeId });
    this.resetSearchInput.emit(true);
  }

  private addServiceToSell(node: NodeService) {
    this.operatableRepository.find(node.id).subscribe(service => {
      this.emitOperatable(service);
    })
  }

  private addProductToSell(node: NodeProduct) {
    if (node.productSummary instanceof SimpleProductSummary) this.emitSimpleProduct(node);
    else if (node.productSummary instanceof ProductByLotsSummary || node.productSummary instanceof VariableProductSummary) this.productDialog(node);
  }

  private emitSimpleProduct(node: NodeProduct) {
    this.operatableRepository.find(node.id).subscribe(simpleProduct => {
      this.emitOperatable(simpleProduct);
    })
  }

  private selectNodeParent() {
    this.nodeParent = this.nodes.find(node => this.isNodeParent(node));
    this.nodeParent ? this.nodes.splice(this.nodes.indexOf(this.nodeParent), 1) : null
    if (this.nodeParent) {
      this.selectBreadcrumb();
    }
  }

  private selectBreadcrumb() {
    this.breadcrumb.resetBreadcrumb();
    this.fillBreadcrumb();
  }

  private fillBreadcrumb() {
    let _nodeParent = this.nodeParent as NodeParent;
    while (_nodeParent != null) {
      let item: BreadcrumbItem = {
        id: _nodeParent?.id,
        name: _nodeParent?.name,
        clickable: !_nodeParent?.hasASingleCategoryAsChild,
      }
      this.breadcrumb.breadcrumbItems.push(item);
      _nodeParent = _nodeParent.parent;
    }
    if (this.breadcrumb) {
      this.breadcrumb.breadcrumbItems[0].current = true;
      this.breadcrumb.breadcrumbItems.reverse();
      this.breadcrumb.breadcrumbItems[0].isHome = true;
    }
  }

  private emitOperatable(operatable: Operatable) {
    this.itemSelected.emit(new OperatableSelectedEvent(operatable));
    this.refreshCategory();
  }

  private refreshCategory() {
    if (this.refreshCategoryOnSelectItem) {
      this.resetSearchInput.emit(true);
    }
  }

  onScrollDown(event: any) {
    this.currentPage = this.currentPage.next;
    this.treeNodeRepository
      .openNode(this.currentPage, this._query, this.currentNodeId)
      .subscribe(data => {
        data.forEach(d => this.nodes.push(d));
        this.changeDetector.markForCheck();
      });
  }

  onNodeClick(node: OperatableTreeNode) {
    if (node) {
      if (this.isNodeParent(node) || this.isNodeCategory(node)) {
        this.currentNodeId = node.id
        this.navigateToCategory();
      }
      else if (this.isNodeService(node)) this.addServiceToSell(node as NodeService);
      else if (this.isNodeProduct(node)) this.addProductToSell(node as NodeProduct);
    }
  }

  onBackCardClick(node: NodeParent) {
    let nodeParent = node;
    while (nodeParent && nodeParent.hasASingleCategoryAsChild) {
      nodeParent = nodeParent.parent;
    }
    this.onNodeClick(nodeParent);
  }

  onBreadcrumbClick(item: BreadcrumbItem) {
    if (item) {
      this.currentNodeId = item.id;
      this.navigateToCategory();
    }
  }

  productDialog(node: NodeProduct) {
    this.enableParentHotkeys.emit(false);
    this.productRepository.find(node.id).subscribe(product => {
      this.dialogRef = this.dialog.open(ProductDialogComponent, {
        width: '100%',
        height: '90%',
      });
      let instance = this.dialogRef.componentInstance;
      instance.product = product;
      this.dialogRef.afterClosed().subscribe(operatable => {
        this.enableParentHotkeys.emit(true)
        if (operatable) {
          this.emitOperatable(operatable);
        }
      });
    });
  }

  isNodeService(node: OperatableTreeNode) {
    return node instanceof NodeService;
  }

  isNodeProduct(node: OperatableTreeNode) {
    return node instanceof NodeProduct;
  }

  isNodeCategory(node: OperatableTreeNode) {
    return node instanceof NodeProductCategory ||
      node instanceof NodeServiceCategory ||
      node instanceof NodeTypeProduct ||
      node instanceof NodeTypeService;
  }

  isNodeParent(node: OperatableTreeNode) {
    return node instanceof NodeParent;
  }

  trackByNodeId(i: number, entity: OperatableTreeNode) {
    return entity?.id;
  }

}
