import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPath, ApiService } from '@eceos/arch';

@Injectable({providedIn:'root'})
export class WeighingMachineService {
  private path: ApiPath;
  constructor(api: ApiService) {
    this.path = api.root.path('deviceHub').path('actives');
  }

  getWeight(weighingMachineId: string): Observable<number> {
    return this.path
      .path(weighingMachineId)
      .path('weight')
      .getJson();
  }
}
