<div class="header">
  <button 
    class="closeButton" 
    mat-icon-button 
    (click)="closeDialog()" 
    tabindex="-1"
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h1 mat-dialog-title>Produtos</h1>
</div>
<mat-form-field class="searchInput" appearance="outline">
  <mat-label>Busca de Produtos</mat-label>
  <input 
    #search 
    matInput type="text" 
    [(ngModel)]="currentQuery" 
    (keyup.enter)="onOperatableSelected()"
  >
  <button 
    *ngIf="currentQuery" 
    mat-icon-button 
    matSuffix 
    (click)="currentQuery=''" 
    tabindex="-1"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<mat-dialog-content 
  #searchResults 
  infiniteScroll 
  [infiniteScrollDistance]="2" 
  [infiniteScrollThrottle]="300"
  [scrollWindow]="false" 
  (scrolled)="onScrollDown($event)"
>
  <mat-selection-list #opList [(ngModel)]="selectedOperatable" [multiple]="false">
    <div *ngIf="!operatables.length && !loading" class="noProductFound">
      <h4>Nenhum Produto Encontrado Para Seu Critério de Busca</h4>
    </div>
    <div *ngIf="loading" class="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!loading">
      <mat-list-option 
        (click)="onOperatableSelected()" 
        (keyup.enter)="onOperatableSelected()"
        *ngFor="let op of operatables; trackBy: trackById" 
        [value]="op"
        class="item"
      >
        <div>
          <div class="name">
            <h4>{{ op?.name }}</h4>
            <span class="barcode">{{ op?.barcode }}</span>
          </div>
          <div class="content">
            <span class="alert">
              <div *ngIf="op?.mainCategory" class="tag category">
                <mat-icon svgIcon="folder-open"></mat-icon>
                <span>{{ op.mainCategory.name }}</span>
              </div>
              <div *ngIf="!op?.isStockAvailable" class="tag no-stock">
                <mat-icon svgIcon="box-open"></mat-icon>
                <span class="alert-information-text">Estoque Indisponível</span>
              </div>
              <div *ngIf="op?.isStockAvailable && op?.stock?.available" class="tag stock">
                <mat-icon svgIcon="box"></mat-icon>
                <span>Estoque: {{ op.stock.available }}</span>
              </div>
            </span>
            <span class="price">{{ op?.defaultSellValue | currency: 'BRL':'symbol' }}</span>
          </div>
        </div>
      </mat-list-option>
    </div>    
  </mat-selection-list>

</mat-dialog-content>