import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalePostFinishComponent } from './sale-post-finish.component';
import { MailInputDialogComponent } from './mail-input-dialog/mail-input-dialog.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [SalePostFinishComponent, MailInputDialogComponent],
  entryComponents: [MailInputDialogComponent],
  imports: [CommonModule, SharedModule]
})
export class SalePostFinishModule {}
