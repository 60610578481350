<app-layout-menu>
  <section class="actions">
    <mat-slide-toggle
      class="primary"
      labelPosition="before"
      [(ngModel)]="showAdvancedConfigs"
      >Avançadas</mat-slide-toggle
    >
  </section>
  <section class="content">
    <mat-card>
      <mat-card-header>
        <div matCardAvatar class="icon-avatar primary-bg pf-bg">
          <mat-icon svgIcon="print"></mat-icon>
        </div>
        <mat-card-title>Impressoras</mat-card-title>
        <mat-card-subtitle>
          Impressora padrão para impressão de comprovantes de venda e notas fiscais
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-printer-settings></app-printer-settings>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <div matCardAvatar class="icon-avatar primary-bg pf-bg">
          <mat-icon svgIcon="balance-scale"></mat-icon>
        </div>
        <mat-card-title>Balanças</mat-card-title>
        <mat-card-subtitle>
          Balança padrão para leitura automática de pesos de produtos vendidos por KG
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-weighing-machine-settings></app-weighing-machine-settings>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <div matCardAvatar class="icon-avatar primary-bg pf-bg">
          <mat-icon svgIcon="home"></mat-icon>
        </div>
        <mat-card-title>Tela inicial</mat-card-title>
        <mat-card-subtitle>
          Tela inicial na qual o seu PDV abrirá por padrão
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-default-home-settings></app-default-home-settings>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <div matCardAvatar class="icon-avatar primary-bg pf-bg">
          <mat-icon svgIcon="cart-plus"></mat-icon>
        </div>
        <mat-card-title>Interface de Venda</mat-card-title>
        <mat-card-subtitle>Modo padrão de venda</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-sale-ui-settings></app-sale-ui-settings>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <div matCardAvatar class="icon-avatar primary-bg pf-bg">
          <mat-icon svgIcon="user-check"></mat-icon>
        </div>
        <mat-card-title>Vendedor padrão</mat-card-title>
        <mat-card-subtitle>
          Vendedor que virá pré selecionado em todas as vendas deste PDV
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-default-sale-vendor></app-default-sale-vendor>
      </mat-card-content>
    </mat-card>
    <ng-container *ngIf="showAdvancedConfigs">
      <mat-card>
        <mat-card-header>
          <div matCardAvatar class="icon-avatar warn-bg">
            <mat-icon svgIcon="receipt"></mat-icon>
          </div>
          <mat-card-title>Formato de Impressão</mat-card-title>
          <mat-card-subtitle>
            Formato padrão de impressão, este formato deve ser suportado pela impressora.
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <app-default-print-format></app-default-print-format>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </section>
</app-layout-menu>
