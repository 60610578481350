import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Hotkey, HotkeySet, navigateIf } from '@eceos/common-utils';
import {
  BrazilianAddress,
  CarrierResponsibleData,
  CarriersRepository,
  Client,
  ClientsRepository,
  CurrentPdvProfileService,
  DelivererResponsibleData,
  DeliverersRepository,
  DeliveryData,
  DeliveryOwnResponsibleData,
  DeliveryPoliciesRepository,
  DeliveryPolicy,
  DeliveryPolicySummary,
  DeliveryResponsibleData,
  PdvProfile,
  Sale,
  SalesRepository,
  WithoutDeliveryResponsibleData
} from '@eceos/domain';
import { lastValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
interface ResponsibleDataOption {
  id: string;
  clazz: new () => DeliveryResponsibleData;
  label: string;
}

@Component({
  selector: 'app-sale-delivery',
  templateUrl: './sale-delivery.component.html',
  styleUrls: ['./sale-delivery.component.scss']
})
export class SaleDeliveryComponent implements OnInit, OnDestroy {
  hotkeys = HotkeySet.of([
    Hotkey.key('f3')
      .description('Mudar para entrega no balcão')
      .do(() => this.withoutDeliveryClick()),
    Hotkey.key('f4')
      .description('Mudar para entrega a domicílio')
      .do(() => this.withDeliveryClick()),
    Hotkey.key('f8')
      .key('ctrl+enter')
      .description('Navegar para o pagamento')
      .do(() => this.submitAndNext())
  ]);

  @ViewChild('addressForm') addressForm: NgControl;

  private client: Client;

  private _deliveryPolicy: DeliveryPolicy;

  private profile: PdvProfile;

  sale: Sale;

  selectedResponsibleDataOption: ResponsibleDataOption;

  loading = false;

  readonly responsibleDataOptions: ResponsibleDataOption[] = [
    {
      id: WithoutDeliveryResponsibleData.name,
      clazz: WithoutDeliveryResponsibleData,
      label: 'Sem responsável'
    },
    {
      id: CarrierResponsibleData.name,
      clazz: CarrierResponsibleData,
      label: 'Transportadora'
    },
    {
      id: DelivererResponsibleData.name,
      clazz: DelivererResponsibleData,
      label: 'Entregador'
    },
    {
      id: DeliveryOwnResponsibleData.name,
      clazz: DeliveryOwnResponsibleData,
      label: 'Responsável próprio'
    }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private repository: SalesRepository,
    private snackBar: MatSnackBar,
    private currentPdvProfileService: CurrentPdvProfileService,
    private clientsRepository: ClientsRepository,
    public deliveryPoliciesRepository: DeliveryPoliciesRepository,
    public deliverersRepository: DeliverersRepository,
    public carriersRepository: CarriersRepository
  ) {}

  get deliveryData(): DeliveryData {
    return this.sale ? this.sale.deliveryData : null;
  }

  get deliveryPolicy(): DeliveryPolicy {
    return this._deliveryPolicy;
  }

  set deliveryPolicy(deliveryPolciy: DeliveryPolicy) {
    if (this._deliveryPolicy !== deliveryPolciy) {
      this._deliveryPolicy = deliveryPolciy;
      this.deliveryData.publishPolicyAndResponsibleFromPolicy(this.deliveryPolicy);
      this.publishSelectedOption();
    }
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap(params => {
          const id = params.get('id');
          return this.repository.find(id);
        }),
        navigateIf(
          (sale: Sale) => sale.isFinalized(),
          (sale: Sale) => this.router.navigate(['/sales', sale.id, 'postFinish'])
        )
      )
      .subscribe(sale => {
        this.sale = sale;
        this.publishSelectedOption();

        this.currentPdvProfileService.profile$
          .pipe(
            navigateIf(
              (profile: PdvProfile) => !profile.withDelivery && this.sale.isWithoutDeliveryData(),
              () => this.navigateNext()
            )
          )
          .subscribe(profile => (this.profile = profile));
      });

    this.hotkeys.enable();
  }

  ngOnDestroy() {
    this.hotkeys.disable();
  }

  nextEnabled(): boolean {
    return (
      !this.loading &&
      this.sale &&
      (this.sale.isWithoutDeliveryData() ||
        (this.sale.isWithDeliveryData() &&
          this.deliveryData !== null &&
          this.deliveryData.isValid() &&
          this.addressForm &&
          this.addressForm.valid))
    );
  }

  withoutDeliveryClick() {
    if (!this.sale.isWithoutDeliveryData()) {
      this.sale.withoutDelivery();
    }
  }

  async withDeliveryClick() {
    if (!this.sale.isWithDeliveryData()) {
      this.sale.withDelivery();
      await this.publishDeliveryPolicy(this.profile ? this.profile.deliveryPolicy : null);
      await this.publishClientAddress();
      this.publishSelectedOption();
    }
  }

  async submitAndNext() {
    if (this.nextEnabled()) {
      try {
        this.loading = true;
        this.sale = await lastValueFrom(this.repository.update(this.sale));
        this.navigateNext();
      } finally {
        this.loading = false;
      }
    } else {
      this.showMessage('Dados da entrega incompletos.');
    }
  }

  onSelectedResponsibleDataOptionChange(responsibleDataOption: ResponsibleDataOption) {
    this.deliveryData.responsibleData = responsibleDataOption
      ? new responsibleDataOption.clazz()
      : null;
  }

  async onDeliveryPolicyChange(deliveryPolicy: DeliveryPolicySummary) {
    await this.publishDeliveryPolicy(deliveryPolicy);
  }

  compareById(obj, anotherObj): boolean {
    return obj && anotherObj && obj.id === anotherObj.id;
  }

  trackById(i, entity): string {
    return entity.id;
  }

  private navigateNext() {
    this.router.navigate(['/sales', this.sale.id, 'payments']);
  }

  private publishSelectedOption() {
    this.selectedResponsibleDataOption =
      this.deliveryData && this.deliveryData.responsibleData
        ? this.responsibleDataOptions.find(
            option => option.id === this.deliveryData.responsibleData.constructor.name
          )
        : this.responsibleDataOptions[0];
  }

  private async publishDeliveryPolicy(deliveryPolicy: DeliveryPolicySummary) {
    if (deliveryPolicy) {
      if (!this.deliveryPolicy || this.deliveryPolicy.id !== deliveryPolicy.id) {
        this.deliveryPolicy = await lastValueFrom(this.deliveryPoliciesRepository
          .find(deliveryPolicy.id));
      }
    } else {
      this.deliveryPolicy = null;
    }
  }

  private async publishClientAddress() {
    if (!this.client && this.sale.client) {
      this.client = await lastValueFrom(this.clientsRepository.find(this.sale.client.id));
    }
    if (this.client && this.client.address instanceof BrazilianAddress) {
      this.deliveryData.address = this.client.address;
    }
  }

  private showMessage(message: string) {
    this.snackBar.open(message, null, { duration: 2000 });
  }
}
