import { MathUtils } from '@eceos/common-utils';
import { Operatable } from '../operatables/operatable';

export abstract class OutbondOperationItem {
  constructor(
    public operatable: Operatable = null,
    public amount = 1,
    public unitaryPrice = 0,
    public discountValue = 0
  ) {
    if (operatable) {
      this.unitaryPrice = operatable.defaultSellValue;
    }
  }

  get totalWithoutDiscount(): number {
    return !isNaN(this.amount) && !isNaN(this.unitaryPrice)
      ? MathUtils.round(this.amount * this.unitaryPrice)
      : 0;
  }

  get total(): number {
    return !isNaN(this.totalWithoutDiscount) && !isNaN(this.discountValue)
      ? MathUtils.round(this.totalWithoutDiscount - this.discountValue)
      : 0;
  }
}
