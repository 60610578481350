<app-loading-overlay *ngIf="weighing"></app-loading-overlay>
<app-layout-back
  [loading]="publishing"
  [hotkeys]="hotkeys"
  [showSearch]="isMobile && touchMode"
  [beforeGoBack]="submitSaleAsyncAndUnsubscribeFn"
  (search)="onSearchChange($event)"
>
  <div class="actions" *ngIf="!isMobile">
    <button mat-icon-button (click)="openDialog()">
      <mat-icon>comment</mat-icon>
    </button>
    <button mat-icon-button (click)="printSale()">
      <mat-icon>print</mat-icon>
    </button>
    <button mat-icon-button tabindex="-1" (click)="toggleMode()">
      <mat-icon *ngIf="simpleMode">touch_app</mat-icon>
      <mat-icon *ngIf="touchMode">search</mat-icon>
    </button>
  </div>

  <div class="actions search-box" *ngIf="isMobile">
    <mat-menu #appMenu="matMenu">
      <button mat-menu-item type="button" (click)="openDialog()">Comentários</button>
      <button mat-menu-item type="button" (click)="printSale()">Impressão</button>
      <button mat-menu-item type="button" (click)="toggleMode()" *ngIf="simpleMode">Modo Seleção</button>
      <button mat-menu-item type="button" (click)="toggleMode()" *ngIf="touchMode">Modo Leitura</button>
    </mat-menu>
    <button mat-icon-button type="button" [matMenuTriggerFor]="appMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

  <div class="content">
    <section class="top" *ngIf="!isMobile">
      <mat-card thinBorder class="saleData">
        <app-sale-details-data
          #saleData
          [sale]="sale"
          (saleChange)="onSaleChange()"
        ></app-sale-details-data>
      </mat-card>
    </section>
    <app-sale-details-mode-simple
      *ngIf="simpleMode"
      class="center"
      [paused]="publishing"
      [sale]="sale"
      (saleChange)="onSaleChange()"
      (weighing)="isWeighing($event)"
      (enableParentHotkeys)="enableHotkeys($event)"
    ></app-sale-details-mode-simple>
    <app-sale-details-mode-touch
      *ngIf="touchMode"
      class="center"
      [paused]="publishing"
      [sale]="sale"
      [isMobile]="isMobile"
      [search]="search"
      (saleChange)="onSaleChange()"
      (weighing)="isWeighing($event)"
      (enableParentHotkeys)="enableHotkeys($event)"
    ></app-sale-details-mode-touch>
  </div>
  <button
    mat-fab
    class="floatDefault"
    *ngIf="nextEnabled"
    [disabled]="publishing"
    (click)="submitSaleAndGoNext()"
  >
    <mat-icon>done</mat-icon>
  </button>
</app-layout-back>
