import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentTable } from './payment-table';
import {
  FinancialDocument,
  FinancialDocumentsRepository,
  PaymentDocumentValue
} from '@eceos/domain';

@Component({
  selector: 'app-payment-table',
  templateUrl: './payment-table.component.html',
  styleUrls: ['./payment-table.component.scss']
})
export class PaymentTableComponent implements OnInit {
  @Input() paymentTable: PaymentTable = undefined;

  @Input() toPay = 0;

  @Output() change = new EventEmitter();

  documents: FinancialDocument[] = [];

  constructor(private financialDocumentsRepository: FinancialDocumentsRepository) {}

  ngOnInit() {
    if (this.paymentTable) {
      this.financialDocumentsRepository.listIncome().subscribe(data => {
        this.documents = data;
        const zeroedPv = this.paymentTable.getPaid().filter(p => p.value === 0);
        if (this.paymentTable.getPaid().length === 0) {
          const newPaid = this.paymentTable.newPaid();
          newPaid.document = this.documents[0];
        } else if (zeroedPv[0] && !zeroedPv[0].document && zeroedPv.length === 1) {
          zeroedPv[0].document = this.documents[0];
        }
      });
    }
  }

  getAvailableDocuments(documentValue?: PaymentDocumentValue): FinancialDocument[] {
    const paymentTableDocuments = this.paymentTable
      .getPaid()
      .filter(e => !documentValue || e.document.id !== documentValue.document.id)
      .map(e => e.document);
    return this.documents.filter(d => !paymentTableDocuments.some(ptd => ptd === d));
  }

  public onPaidValueChange(pv: PaymentDocumentValue) {
    const zeroedPv = this.paymentTable.getPaid().filter(p => p.value === 0);
    if (zeroedPv.length === 0 && this.paymentTable.getTotalPaid() < this.toPay) {
      const availableDocument = this.getAvailableDocuments()[0];
      if (availableDocument) {
        this.paymentTable.newPaid().document = availableDocument;
      }
    } else if (zeroedPv.length > 1 || this.paymentTable.getTotalPaid() >= this.toPay) {
      this.paymentTable
        .getPaid()
        .filter(p => p.document !== pv.document && p.value === 0)
        .forEach(p => {
          this.paymentTable.removePaid(p);
        });
    }
    this.change.emit(this.paymentTable);
  }

  public onCompleteClicked(pv: PaymentDocumentValue) {
    this.paymentTable.getPaid().forEach(p => {
      if (p.document !== pv.document) {
        this.paymentTable.removePaid(p);
      } else {
        p.value = this.toPay;
      }
    });
    this.change.emit(this.paymentTable);
  }

  public getPlaceHolder(index: number): string {
    if (this.paymentTable.getPaid().length > 1) {
      return 'Forma de Pagamento ' + (index + 1);
    }
    return 'Forma de Pagamento';
  }

  isFocused(v: PaymentDocumentValue) {
    return this.paymentTable.getPaid().length === 1;
  }

  trackByFn(v: PaymentDocumentValue) {
    return v && v.document ? v.document.id : v;
  }
}
