import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@eceos/default-shared';
import { LayoutMenuComponent } from './layout-menu.component';
import { MenuComponent } from './menu/menu.component';

@NgModule({
  imports: [CommonModule, DefaultSharedModule],
  declarations: [LayoutMenuComponent, MenuComponent],
  exports: [LayoutMenuComponent, MenuComponent]
})
export class LayoutMenuModule {}
