import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NodeProduct, NodeProductCategory, NodeService, NodeServiceCategory, NodeTypeProduct, NodeTypeService, OperatableTreeNode } from '@eceos/domain';
import { ProductByLotsSummary, SimpleProductSummary, VariableProductSummary } from 'libs/domain/src/lib/products/product-summary';

type NodeType = 'PRODUCT' | 'SERVICE' | 'CATEGORY';
type ProductType = 'SIMPLE_PRODUCT' | 'PRODUCT_BY_LOTS' | 'VARIABLE_PRODUCT';

@Component({
  selector: 'app-operatable-tree-node-card',
  templateUrl: './operatable-tree-node-card.component.html',
  styleUrls: ['./operatable-tree-node-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperatableTreeNodeCardComponent implements OnInit {

  _operatableNode: OperatableTreeNode;

  operatableNodeType: NodeType;

  productType: ProductType;

  @Output() selectedNode = new EventEmitter<OperatableTreeNode>();

  constructor() { }

  ngOnInit(): void {

  }

  @Input()
  set operatableNode(node: OperatableTreeNode) {
    this._operatableNode = node;
    this.selectNodeType();
  }

  selectNodeType() {
    if (this._operatableNode instanceof NodeProduct) {
      this.operatableNodeType = 'PRODUCT';
      this.selectProductType();
    } else if (this._operatableNode instanceof NodeService) {
      this.operatableNodeType = 'SERVICE';
    } else if (
      this._operatableNode instanceof NodeProductCategory ||
      this._operatableNode instanceof NodeServiceCategory ||
      this._operatableNode instanceof NodeTypeService ||
      this._operatableNode instanceof NodeTypeProduct
    ) {
      this.operatableNodeType = 'CATEGORY';
    }
  }

  selectProductType() {
    if ((this._operatableNode as NodeProduct).productSummary instanceof SimpleProductSummary) {
      this.productType = 'SIMPLE_PRODUCT';
    } else if ((this._operatableNode as NodeProduct).productSummary instanceof ProductByLotsSummary) {
      this.productType = 'PRODUCT_BY_LOTS';
    } else if ((this._operatableNode as NodeProduct).productSummary instanceof VariableProductSummary) {
      this.productType = 'VARIABLE_PRODUCT';
    }
  }

  isNodeProduct() {
    return this.operatableNodeType === 'PRODUCT';
  }

  isNodeService() {
    return this.operatableNodeType === 'SERVICE';
  }

  isNodeCategory() {
    return this.operatableNodeType === 'CATEGORY';
  }

  isSimpleProduct() {
    return this.productType === 'SIMPLE_PRODUCT';
  }

  isProductByLots() {
    return this.productType === 'PRODUCT_BY_LOTS';
  }

  isVariableProduct() {
    return this.productType === 'VARIABLE_PRODUCT';
  }

  onNodeClick() {
    if (this._operatableNode) {
      this.selectedNode.emit(this._operatableNode);
    }
  }

}
