import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localept from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ApiModule,
  API_ROOT_URL,
  ArchModule,
  AuthModule,
  AUTH_TOKEN_NAME,
  HttpErrorHandler,
  HttpErrorMessageHandler,
  LOGIN_ROUTE
} from '@eceos/arch';
import { AssetsModule } from '@eceos/assets';
import { COOKIE_NAME, CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from '@eceos/common-components';
import { DomainModule } from '@eceos/domain';
import { FontAwesomeRegister } from '@eceos/shared-third-party';
import {
  faBalanceScale,
  faCartPlus,
  faMotorcycle,
  faPrint,
  faReceipt,
  faShippingFast,
  faTruck,
  faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../environments/environment';
import { SettingsService } from './settings.service';
import { SyncGuard } from './sync/sync.guard';

registerLocaleData(localept, 'pt');

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: false
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DomainModule,
    ApiModule,
    AuthModule,
    ArchModule,
    AssetsModule
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
    { provide: AUTH_TOKEN_NAME, useValue: 'pdv.authToken' },
    { provide: COOKIE_NAME, useValue: 'pdv.cookieConsent' },
    { provide: LOGIN_ROUTE, useValue: '/login' },
    { provide: API_ROOT_URL, useValue: environment.apiRoot },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'standard' }
    },
    { provide: HttpErrorHandler, useClass: HttpErrorMessageHandler, multi: true },
    SyncGuard,
    SettingsService
  ]
})
export class CoreModule {
  constructor(faRegister: FontAwesomeRegister) {
    faRegister.registerAll(
      faUserCheck,
      faBalanceScale,
      faReceipt,
      faCartPlus,
      faPrint,
      faTruck,
      faMotorcycle,
      faShippingFast
    );
  }
}
