import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultSharedModule } from '@eceos/default-shared';
import { CommonPagesModule } from '@eceos/common-pages';
import { LayoutModule } from './layout/layout.module';

@NgModule({
  imports: [CommonModule, DefaultSharedModule, CommonPagesModule, LayoutModule],
  exports: [DefaultSharedModule, CommonPagesModule, LayoutModule]
})
export class SharedModule {}
