import { SaleItemListComponent } from './sale-item-list/sale-item-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleItemDetailComponent } from './sale-item-detail/sale-item-detail.component';
import { SaleDetailsComponent } from './sale-details.component';
import { SharedModule } from '../../../shared/shared.module';
import { SaleOperatablesListComponent } from './sale-operatables-list/sale-operatables-list.component';
import { SaleItemLineComponent } from './sale-item-list/sale-item-line/sale-item-line.component';
import { SaleDetailsObservationsComponent } from './sale-details-observations/sale-details-observations.component';
import { SaleDetailsModeTouchComponent } from './sale-details-mode-touch/sale-details-mode-touch.component';
import { SaleDetailsModeSimpleComponent } from './sale-details-mode-simple/sale-details-mode-simple.component';
import { SaleDetailsDataComponent } from './sale-details-data/sale-details-data.component';
import { SaleItemAmountSummaryPipe } from './sale-item-amount-summary.pipe';
import { OperatableDialogComponent } from './operatable-dialog/operatable-dialog.component';
import { SaleOperatableTreeComponent } from './sale-operatable-tree/sale-operatable-tree.component';
import { ProductDialogComponent } from './sale-operatable-tree/product-dialog/product-dialog.component';
import { SaleItemUpdateDialogComponent } from './sale-details-mode-touch/sale-item-update-dialog/sale-item-update-dialog.component';
import { OperatableTreeNodeCardComponent } from './sale-operatable-tree/operatable-tree-node-card/operatable-tree-node-card.component';

@NgModule({
  declarations: [
    SaleDetailsComponent,
    SaleItemDetailComponent,
    SaleOperatablesListComponent,
    SaleDetailsObservationsComponent,
    SaleItemListComponent,
    SaleItemLineComponent,
    SaleDetailsModeTouchComponent,
    SaleDetailsModeSimpleComponent,
    SaleDetailsDataComponent,
    SaleItemAmountSummaryPipe,
    OperatableDialogComponent,
    SaleOperatableTreeComponent,
    ProductDialogComponent,
    SaleItemUpdateDialogComponent,
    OperatableTreeNodeCardComponent,
  ],
  entryComponents: [SaleDetailsObservationsComponent],
  imports: [CommonModule, SharedModule]
})
export class SaleDetailsModule {}
