import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Breadcrumb } from '@eceos/common-components';
import { BreadcrumbItem } from '.';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent {

  @Input() loading: boolean;
  @Input() breadcrumb: Breadcrumb;
  @Output() itemClick = new EventEmitter<BreadcrumbItem>();

  onBreadcrumbItemClick(item: BreadcrumbItem) {
    if (item) {
      this.itemClick.emit(item)
    }
  }

  trackById(i: number, entity: BreadcrumbItem) {
    return entity?.id
  }

}
