<div class="header">
    <button class="closeButton" mat-icon-button (click)="closeDialog()" tabindex="-1">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h1 mat-dialog-title>{{product.name}}</h1>
</div>

<mat-dialog-content *ngIf="product.isVariableProduct">
    <mat-selection-list [(ngModel)]="id" [multiple]="false">
        <mat-list-option (click)="onProductSelect()" (keyup.enter)="onProductSelect()"
            *ngFor="let variation of product.variations; trackBy: trackById" [value]="variation.id">
            <div>
                <div class="name">
                    <span>
                        <h4>{{product.name}}</h4>
                    </span>
                    <span *ngFor="let v of variation.variations; trackBy: trackById">
                        <h4>{{v.value.name}}</h4>
                    </span>
                </div>
                <div class="barcode">
                    {{variation.barcode}}
                </div>
            </div>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>

<mat-dialog-content *ngIf="product.isProductByLots">
    <mat-selection-list [(ngModel)]="id" [multiple]="false">
        <mat-list-option (click)="onProductSelect()" (keyup.enter)="onProductSelect()"
            *ngFor="let lot of product.lots; trackBy: trackById" [value]="lot.id">
            <div>
                <div class="name">
                    <span>
                        <h4>{{product.name}}</h4>
                    </span>
                </div>
                <div class="barcode">
                    {{lot.barcode}}
                </div>
            </div>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>