import { MathUtils } from '@eceos/common-utils';
import { JsonEntity, SgvJson } from '@eceos/arch';
export abstract class Addition implements JsonEntity {
  abstract applyOn(value: number): number;

  abstract get(value: number): number;

  abstract getValue(value: number): number;

  abstract getPercent(value: number): number;

  abstract toJson(): any;

  static fromJson(json: any): Addition {
    if (json && json.type) {
      switch (json.type) {
        case 'noAddition':
          return NoAddition.fromJson(json);
        case 'percent':
          return PercentAddition.fromJson(json);
        case 'value':
          return ValueAddition.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class NoAddition extends Addition {
  applyOn(value: number): number {
    return value;
  }

  get(value: number): number {
    return 0;
  }

  getValue(value: number): number {
    return 0;
  }

  getPercent(value: number): number {
    return 0;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'noAddition'
    });
  }

  static fromJson(json: any): NoAddition {
    return SgvJson.from.simple(json, NoAddition);
  }
}

export class PercentAddition extends Addition {
  constructor(public value = 0) {
    super();
  }

  applyOn(value: number): number {
    return value != null ? MathUtils.round(value + this.get(value)) : 0;
  }

  get(value: number): number {
    return value != null ? MathUtils.round((this.value / 100) * value, 5) : 0;
  }

  getValue(value: number): number {
    return MathUtils.round((this.value / 100) * value);
  }

  getPercent(value: number): number {
    return this.value;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'percent'
    });
  }

  static fromJson(json: any): PercentAddition {
    return SgvJson.from.simple(json, PercentAddition);
  }
}

export class ValueAddition extends Addition {
  constructor(public value = 0) {
    super();
  }

  applyOn(value: number): number {
    return value != null ? MathUtils.round(this.value + value) : 0;
  }

  get(value: number): number {
    return MathUtils.round(this.value);
  }

  getValue(value: number): number {
    return this.value;
  }

  getPercent(value: number): number {
    return MathUtils.round((this.value / value) * 100);
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'value'
    });
  }

  static fromJson(json: any): ValueAddition {
    return SgvJson.from.simple(json, ValueAddition);
  }
}
