<mat-card *ngIf="isNodeCategory()" (click)="onNodeClick()" (keydown.enter)="onNodeClick()"
    class="operatable-card category" tabindex="0">
    <div matCardAvatar class="category-icon">
        <mat-icon>local_offer</mat-icon>
    </div>
    <mat-card-title>{{ _operatableNode?.name }}</mat-card-title>
</mat-card>

<mat-card *ngIf="isNodeProduct()" (click)="onNodeClick()" (keydown.enter)="onNodeClick()"
    class="operatable-card product" tabindex="0">
    <mat-card-header>
        <div matCardAvatar *ngIf="isSimpleProduct()" class="icons simple-product">
            <mat-icon>view_agenda</mat-icon>
        </div>
        <div matCardAvatar *ngIf="isVariableProduct()" class="icons variable-product">
            <mat-icon>view_module</mat-icon>
        </div>
        <div matCardAvatar *ngIf="isProductByLots()" class="icons product-by-lot">
            <mat-icon>view_column</mat-icon>
        </div>
        <div>
            <mat-card-title>{{ _operatableNode?.name }}</mat-card-title>
        </div>
    </mat-card-header>
    <mat-card-content>
        <section *ngIf="_operatableNode?.productSummary?.barcode">
            <mat-icon svgIcon="barcode"></mat-icon>
            <div>
                {{ _operatableNode?.productSummary?.barcode }}
            </div>
        </section>
        <section *ngIf="_operatableNode?.productSummary?.stock?.available">
            <mat-icon svgIcon="box"></mat-icon>
            <div>
                {{_operatableNode?.productSummary?.stock?.available}}
                {{_operatableNode?.productSummary?.defaultUnity?.acronym}}
            </div>
        </section>
        <section *ngIf="_operatableNode?.productSummary?.defaultSellValue">
            <mat-icon svgIcon="money-bill"></mat-icon>
            <div>
                {{ _operatableNode?.productSummary?.defaultSellValue | currency: 'BRL':'symbol' }}
            </div>
        </section>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="isNodeService()" (click)="onNodeClick()" (keydown.enter)="onNodeClick()"
    class="operatable-card service" tabindex="0">
    <mat-card-header>
        <div matCardAvatar class="icons service-icon">
            <mat-icon svgIcon="wrench"></mat-icon>
        </div>
        <div>
            <mat-card-title>{{ _operatableNode?.name }}</mat-card-title>
        </div>
    </mat-card-header>
</mat-card>