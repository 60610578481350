<section class="bg fillPrimary">
  <img class="logo" src="/assets/images/logo_eceos_white.svg" />

  <mat-card class="loginCard">
    <mat-card-title>Autenticação</mat-card-title>
    <form class="fields" (submit)="submitLogin(identifier.value, username.value, password.value)">
      <mat-form-field>
        <mat-label>Identificador do PDV</mat-label>
        <input matInput #identifier />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Usuário</mat-label>
        <input matInput #username />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Senha</mat-label>
        <input matInput #password type="password" />
      </mat-form-field>
      <button mat-raised-button class="ok-btn" type="submit">Entrar</button>
    </form>
  </mat-card>
</section>
