<div class="line">
  <mat-form-field class="labelContainer" thin>
    <input matInput value="Acréscimo" readonly tabindex="-1" />
  </mat-form-field>
  <mat-form-field class="inputContainer percent" thin>
    <mat-label>Acréscimo %</mat-label>
    <input matInput type="number" [(ngModel)]="percent" min="0" />
    <span matSuffix>%</span>
  </mat-form-field>
  <mat-form-field class="inputContainer" thin>
    <mat-label>Acréscimo R$</mat-label>
    <input matInput currencyMask [(ngModel)]="value" [options]="{ allowNegative: false }" />
  </mat-form-field>
</div>
