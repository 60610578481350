import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RemoteWeighingMachineSummary, RemoteWeighingMachinesRepository } from '@eceos/domain';
import { SettingsService } from '../../../core/settings.service';

@Component({
  selector: 'app-weighing-machine-settings',
  templateUrl: './weighing-machine-settings.component.html',
  styleUrls: ['./weighing-machine-settings.component.scss']
})
export class WeighingMachineSettingsComponent implements OnInit {
  readonly weighingMachines: Observable<RemoteWeighingMachineSummary[]>;

  constructor(
    private repository: RemoteWeighingMachinesRepository,
    private settingsRepository: SettingsService
  ) {
    this.weighingMachines = this.repository.list();
  }

  ngOnInit() {}

  get defaultWeighingMachine(): RemoteWeighingMachineSummary {
    return this.settingsRepository.defaultWeighingMachine;
  }

  get weighingMachineRepository(): RemoteWeighingMachinesRepository {
    return this.repository;
  }

  onWeighingMachineSelected(value: RemoteWeighingMachineSummary) {
    this.settingsRepository.defaultWeighingMachine = value;
  }

  compareById(obj1: RemoteWeighingMachineSummary, obj2: RemoteWeighingMachineSummary) {
    return obj1 === obj2 || (obj1 && obj2 && obj1.id === obj2.id);
  }
}
