import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { SaleItem } from '@eceos/domain';
import { SaleItemLineComponent } from './sale-item-line/sale-item-line.component';

@Component({
  selector: 'app-sale-item-list',
  template: `
    <mat-list>
      <ng-container *ngFor="let item of showItems; let i = index">
        <app-sale-item-line
          #lineItem
          [item]="item"
          [class.selected]="isSelected(item)"
          (click)="onItemClick(item)"
          (keydown.enter)="onItemClick(item)"
          [number]="showItems.length - i"
        >
        </app-sale-item-line>
      </ng-container>
    </mat-list>
  `,
  styleUrls: ['sale-item-list.component.scss']
})
export class SaleItemListComponent {
  @Input() items: SaleItem[];

  @Input() reverse = true;

  @Input() highlightSelected = true;

  @Input() selected: SaleItem;

  @Output() selectedChange = new EventEmitter<SaleItem>();

  @ViewChildren('lineItem', {read: SaleItemLineComponent}) saleItemLineRef: QueryList<SaleItemLineComponent>

  constructor() { }

  @Input()
  set focus(focus: boolean){
    console.log(focus)
    if(focus){
      this.requestFocus();
    }
  }

  isSelected(item: SaleItem) {
    return this.highlightSelected && this.selected === item;
  }

  onItemClick(item: SaleItem) {
    this.selected = item;
    this.selectedChange.emit(item);
  }

  get showItems() {
    if (!this.reverse) {
      return this.items.slice(0);
    } else {
      return this.items.slice(0).reverse();
    }
  }
  requestFocus() {
    if (this.saleItemLineRef) {
      this.saleItemLineRef.first.requestFocus();
    }
  }
}
