import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GuestCardsModule } from './guest-cards/guest-cards.module';
import { LoginModule } from './login/login.module';
import { SalesModule } from './sales/sales.module';
import { SettingsModule } from './settings/settings.module';
import { HomeModule } from './home/home.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, LoginModule, SettingsModule, SalesModule, GuestCardsModule, HomeModule]
})
export class FeaturesModule {}
