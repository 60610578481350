<div class="content" *ngIf="sale">
  <section class="main">
    <mat-card thinBorder class="search">
      <mat-form-field thin floatLabel="always">
        <mat-label>Produto</mat-label>
        <input #search appBarcode autofocus autocomplete="off" matInput/>
        <button *ngIf="search.value" mat-icon-button matSuffix (click)="resetSearchInput()" tabindex="-1">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-card>
    <section class="items">
      <app-sale-operatable-tree [query]="search.value" (itemSelected)="onItemSelected($event)"
        (enableParentHotkeys)="enableHotkeys($event)" (resetSearchInput)="resetSearchInput($event)"
        [refreshCategoryOnSelectItem]="true">
      </app-sale-operatable-tree>
    </section>
  </section>

  <section class="side">
    <mat-card thinBorder>
      <app-sale-item-list #itemList class="itensList" [items]="sale.items" [(selected)]="currentItem"
        (selectedChange)="updateSaleItemDialog($event)"></app-sale-item-list>
    </mat-card>
    <mat-card thinBorder class="total">
      <mat-label>Total</mat-label>
      <mat-form-field thin>
        <input matInput readonly [value]="sale.total | currency: 'BRL':'symbol'" />
      </mat-form-field>
    </mat-card>
  </section>

  <section class="details" [class.showSaleDetails]="showSaleDetails">
    <div class="expand" (click)="detailsNavigation()">
      <mat-icon *ngIf="!showSaleDetails">expand_less</mat-icon>
      <mat-icon *ngIf="showSaleDetails">expand_more</mat-icon>
    </div>

    <div class="details-content" [class.details-content-expand]="showSaleDetails">
      <mat-tab-group mat-stretch-tabs >
        <mat-tab label="Itens">
          <app-sale-item-list [items]="sale.items" [highlightSelected]="true"
            (selectedChange)="updateSaleItemDialog($event)"></app-sale-item-list>
          <h4 class="no-sale-items" *ngIf="!sale?.totalItems">Esta venda ainda não possui itens</h4>
        </mat-tab>
        <mat-tab label="Informações">
          <app-sale-details-data class="informations" [sale]="sale"></app-sale-details-data>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="total-price" (click)="footerDetailsNavigationClick()">
      <div class="total">{{ sale?.total | currency: 'BRL':'symbol' }}</div>
      <div class="itens">{{ sale?.totalItems }} itens</div>
    </div>
  </section>

</div>