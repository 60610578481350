import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CurrentPdvProfileService, PdvProfile, SaleItem } from '@eceos/domain';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { SettingsService } from '../../../../core/settings.service';
import { WeightService } from '../../../../core/weighing-machines/weight.service';

@Component({
  selector: 'app-sale-item-detail',
  templateUrl: './sale-item-detail.component.html',
  styleUrls: ['./sale-item-detail.component.scss']
})
export class SaleItemDetailComponent implements OnInit, AfterViewInit {
  @Output() itemChange = new EventEmitter<SaleItem>();

  @Output() complete = new EventEmitter();

  @Output() weighing = new EventEmitter<boolean>();

  @Output() onEnter = new EventEmitter<boolean>();

  @Input() inputButtons = false;

  @Input() enableHotkeys = false;

  @ViewChild('addButton', { read: ElementRef }) addButton: ElementRef;

  @ViewChild('minusButton', { read: ElementRef }) minusButton: ElementRef;

  readonly currentProfile$: Observable<PdvProfile>;

  private _item: SaleItem;

  isPriceChangeRestricted$: Observable<boolean>;

  constructor(
    private settingsService: SettingsService,
    private weightService: WeightService,
    currentPdvProfileService: CurrentPdvProfileService
  ) {
    this.currentProfile$ = currentPdvProfileService.profile$;
  }
  ngAfterViewInit(): void {
    if (this.inputButtons) {
      setTimeout(() => this.addButtonRequestFocus(), 200)
    }
  }

  ngOnInit() { }

  @Input()
  set item(item: SaleItem) {
    this._item = item;
    this.isPriceChangeRestricted$ = this.currentProfile$.pipe(
      map(p => !(!p.restrictPriceChange || this.item.operatable.defaultSellValue === 0))
    )
  }

  get item(): SaleItem {
    return this._item;
  }

  get active() {
    return this.item != null;
  }

  get amount(): number {
    return this.active ? this.item.amount : 0;
  }

  set amount(amount: number) {
    if (!Number.isNaN(amount) && amount > 0) {
      this.item.amount = amount;
      this.itemChange.emit(this.item);
    }
  }

  get unitaryPrice(): number {
    return this.active ? this.item.unitaryPrice : 0.0;
  }

  set unitaryPrice(unitaryPrice: number) {
    this.item.unitaryPrice = unitaryPrice;
    this.itemChange.emit(this.item);
  }

  get total(): number {
    return this.active ? this.item.total : 0.0;
  }

  get weightable(): boolean {
    return this.item && this.item.isWeightable;
  }

  get hasDefaultWeighingMachine(): boolean {
    return Boolean(this.settingsService.defaultWeighingMachine);
  }

  getWeight() {
    this.weighing.emit(true);
    return this.weightService
      .getWeight(this.item)
      .pipe(finalize(() => this.weighing.emit(false)))
      .subscribe(item => {
        this.item = item;
        this.itemChange.emit(this.item);
      });
  }

  validateAmount(input: HTMLInputElement): void {
    input.value = this.item.amount.toString();
  }

  increaseAmount() {
    this.amount++;
  }

  decreaseAmount() {
    this.amount--;
  }

  onKeydown(event: KeyboardEvent) {
    if (this.enableHotkeys) {
      if (event.code === "NumpadAdd" || event.code === "Equal") {
        this.increaseAmount();
        this.addButtonRequestFocus();
      } else if (event.code === "NumpadSubtract" || event.code === "Minus") {
        this.decreaseAmount();
        this.minusButtonRequestFocus();
      } else if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
      }
    } else if (event.code === "Enter" || event.code === "NumpadEnter") {
      this.onEnter.emit(true);
    }
  }

  quantityButtonCLick(event: PointerEvent) {
    if (this.inputButtons) {
      event.stopPropagation();
    }
  }

  addButtonRequestFocus() {
    this.addButton.nativeElement.focus();
  }

  minusButtonRequestFocus() {
    this.minusButton.nativeElement.focus();
  }

}
