import { Component, Input, OnInit } from '@angular/core';
import { CurrentPdvProfileService, PdvProfile, Sale } from '@eceos/domain';

@Component({
  selector: 'app-payment-discount',
  templateUrl: './payment-discount.component.html',
  styleUrls: ['./payment-discount.component.scss']
})
export class PaymentDiscountComponent implements OnInit {
  @Input() sale: Sale;

  pdvProfile: PdvProfile;

  constructor(currentPdvProfileService: CurrentPdvProfileService) {
    currentPdvProfileService.profile$.subscribe(
      value => (this.pdvProfile = value),
      err => console.error(err)
    );
  }

  ngOnInit() {}

  get value(): number {
    return this.sale.discount.getValue(this.sale.totalPriceWithoutFreightAndDiscount);
  }

  set value(value: number) {
    this.sale.asPaymentValueDiscount(this.getValue(value));
  }

  get percent(): number {
    return this.sale.discount.getPercent(this.sale.totalPriceWithoutFreightAndDiscount);
  }

  set percent(percent: number) {
    this.sale.asPaymentPercentDiscount(this.getPercent(percent));
  }

  get maxPercent(): number {
    return !this.pdvProfile || this.pdvProfile.maxDiscount.isEmpty()
      ? 100
      : this.pdvProfile.maxDiscount.getPercent(this.sale.totalPriceWithoutFreightAndDiscount);
  }

  get maxValue(): number {
    return !this.pdvProfile || this.pdvProfile.maxDiscount.isEmpty()
      ? this.sale.totalPriceWithoutFreightAndDiscount
      : this.pdvProfile.maxDiscount.getValue(this.sale.totalPriceWithoutFreightAndDiscount);
  }

  private getValue(value: number): number {
    return this.pdvProfile.maxDiscount.isEmpty() || value <= this.maxValue ? value : this.maxValue;
  }

  private getPercent(percent: number): number {
    return this.pdvProfile.maxDiscount.isEmpty() || percent <= this.maxPercent
      ? percent
      : this.maxPercent;
  }
}
