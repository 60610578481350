import { Injectable } from '@angular/core';
import { CurrentInfoService } from './current-info.service';
import { AuthenticatedPdv } from '../core/authenticated';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PdvProfile } from '../pdvs/pdv-profiles';

@Injectable({ providedIn: 'root' })
export class CurrentPdvProfileService {
  readonly profile$: Observable<PdvProfile>;

  constructor(loginMetadata: CurrentInfoService) {
    this.profile$ = loginMetadata.$value.pipe(
      map((v) => v.authenticated),
      filter((v) => v instanceof AuthenticatedPdv),
      map((v) => (v as AuthenticatedPdv).profile)
    );
  }
}
