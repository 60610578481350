import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EceosLayoutBreakpointsService, InterceptorConfig } from '@eceos/arch';
import { Hotkey, HotkeySet, navigateIf } from '@eceos/common-utils';
import { CurrentPdvProfileService, PdvProfile, Sale, SalesRepository } from '@eceos/domain';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { SettingsService } from '../../../core/settings.service';
import { SyncCheck } from './../../../core/sync/sync-check';
import { ProductSelectorMode } from './product-selector-mode';
import { SaleDetailsDataComponent } from './sale-details-data/sale-details-data.component';
import { SaleDetailsModeSimpleComponent } from './sale-details-mode-simple/sale-details-mode-simple.component';
import { SaleDetailsObservationsComponent } from './sale-details-observations/sale-details-observations.component';

@Component({
  selector: 'app-sale-details',
  templateUrl: './sale-details.component.html',
  styleUrls: ['./sale-details.component.scss']
})
export class SaleDetailsComponent implements OnInit, OnDestroy, SyncCheck {
  hotkeys = HotkeySet.of([
    Hotkey.key('f2')
      .description('Alterar vendedor, cliente e CPF/CNPJ')
      .do(() => this.focusOnSaleData()),
    Hotkey.key('f4')
      .description('Alterar entre modos de seleção/leitura')
      .do(() => this.toggleMode()),
    Hotkey.key('f8')
      .key('ctrl+enter')
      .description('Concluir e navegar para o pagamento')
      .do(() => this.submitSaleAndGoNext()),
    Hotkey.key('f9')
      .description('Adicionar observação')
      .do(() => this.openDialog()),
    Hotkey.key('f10')
      .description('Imprimir venda')
      .do(() => this.printSale())
  ]);

  sale: Sale = new Sale();

  isMobile = false;

  search = '';

  publishing = false;

  synchronizing = false;

  dialogRef: Subscription;

  private publishSaleListener = new Subject<Sale>();

  @ViewChild(SaleDetailsDataComponent)
  saleDataElement: SaleDetailsDataComponent;

  @ViewChild(SaleDetailsModeSimpleComponent)
  saleDetailsSimpleElement: SaleDetailsModeSimpleComponent;

  mode = ProductSelectorMode.SIMPLE;

  weighing = false;

  profile: PdvProfile;

  private publishSaleListenerSubscription: Subscription;

  breakpointSubscription: Subscription;

  submitSaleAsyncAndUnsubscribeFn = () => this.submitSaleAsyncAndUnsubscribe();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private repository: SalesRepository,
    private location: Location,
    private settingsRepository: SettingsService,
    private snackbar: MatSnackBar,
    private currentPdvProfileService: CurrentPdvProfileService,
    public dialog: MatDialog,
    public layoutBreakpoints: EceosLayoutBreakpointsService,
  ) {
    this.publishSaleListenerSubscription = this.publishSaleListener
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe(_ => this.submitSaleAsync());
    this.mode = this.settingsRepository.defaultProductSelectorMode;
    this.currentPdvProfileService.profile$.subscribe(profile => (this.profile = profile));

    this.breakpointSubscription = this.layoutBreakpoints.isTablet$
      .subscribe((match: boolean) => { this.isMobile = match });
  }

  get isWithDelivery(): boolean {
    return this.sale.isWithDeliveryData() || (this.profile && this.profile.withDelivery);
  }

  isSync(): boolean {
    if (this.synchronizing) {
      this.snackbar.open('Em sincronização com o servidor', null, {
        duration: 2000
      });
    }
    return !this.synchronizing;
  }

  publishSale() {
    this.synchronizing = true;
    this.publishSaleListener.next(this.sale);
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap(params => {
          const id = params.get('id');
          if ('new' === id) {
            return new Observable(ob => ob.next(this.createNewSale()));
          } else {
            return this.repository.find(id);
          }
        }),
        navigateIf(
          (sale: Sale) => sale.isFinalized(),
          (sale: Sale) => this.router.navigate(['/sales', sale.id, 'postFinish'])
        )
      )
      .subscribe(
        (sale: Sale) => (this.sale = sale),
        e => {
          console.error(e);
          this.location.back();
        }
      );
    this.hotkeys.enable();
  }

  ngOnDestroy() {
    this.hotkeys.disable();
    this.breakpointSubscription.unsubscribe();
  }

  private createNewSale() {
    const sale = new Sale();
    sale.vendor = this.settingsRepository.defaultVendor;
    return sale;
  }

  onSaleChange(): void {
    this.publishSale();
  }

  async submitSaleAsync() {
    const errorHandler = (err: any) => {
      console.error(err);
    };
    const finishListener = () => {
      this.publishing = false;
      this.synchronizing = false;
    };

    this.publishing = true;

    if (this.hasItens) {
      if (this.sale.key) {
        try {
          this.sale = await lastValueFrom(this.repository.update(this.sale));
        } catch (e) {
          errorHandler(e);
        } finally {
          finishListener();
        }
      } else {
        try {
          this.sale = await lastValueFrom(this.repository.insert(this.sale));
          this.updateLocation();
        } catch (e) {
          errorHandler(e);
        } finally {
          finishListener();
        }
      }
    } else if (this.sale.key) {
      try {
        await lastValueFrom(this.repository.delete(this.sale));
        this.sale = this.createNewSale();
        this.updateLocation();
      } catch (e) {
        errorHandler(e);
      } finally {
        finishListener();
      }
    } else {
      finishListener();
    }
  }

  async submitSaleAsyncAndUnsubscribe() {
    this.publishSaleListenerSubscription.unsubscribe();
    await this.submitSaleAsync();
  }

  async submitSaleAndGoNext() {
    this.publishSaleListenerSubscription.unsubscribe();
    await this.submitSaleAsync();
    this.goNext();
  }

  get nextEnabled() {
    return this.hasItens;
  }

  get hasItens() {
    return this.sale && this.sale.items.length > 0;
  }

  openDialog() {
    this.dialog.closeAll();
    this.dialogRef = this.dialog
      .open(SaleDetailsObservationsComponent, {
        width: '50em',
        data: { details: this.sale.details, hotkeys: this.hotkeys }
      })
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          return;
        }
        this.sale.details = result;
        this.onSaleChange();
      });
  }

  goNext() {
    if (this.nextEnabled) {
      this.router.navigate(['/sales', this.sale.id, this.isWithDelivery ? 'delivery' : 'payments']);
    }
  }

  updateLocation(): void {
    this.location.replaceState(`/sales/${this.sale.key ? this.sale.id : 'new'}/details`);
  }

  focusOnSaleData(): void {
    this.saleDataElement.requestFocus();
  }

  toggleMode() {
    if (this.simpleMode) {
      this.mode = ProductSelectorMode.TOUCH;
    } else if (this.touchMode) {
      this.mode = ProductSelectorMode.SIMPLE;
    }
  }
  printSale() {
    if (this.hasDefaultPrinter) {
      this.repository
        .printTo(
          this.sale,
          this.settingsRepository.defaultReceiptPrinter,
          this.settingsRepository.defaultPrintFormat,
          { autoCatch: InterceptorConfig.NO_INTERCEPT }
        )
        .subscribe(
          r => {
            this.snackbar.open('Impressão enviada com sucesso', null, {
              duration: 1000
            });
          },
          e => this.showError(e, 'Erro ao enviar impressão')
        );
    } else {
      this.showMessage('Impressora padrão não encontrada');
    }
  }

  isWeighing(weighing: boolean) {
    this.weighing = weighing;
  }

  enableHotkeys(enable: boolean) {
    if (enable) {
      this.hotkeys.enable();
    }
    else {
      this.hotkeys.disable();
    }
  }

  onSearchChange(search: string) {
    this.search = search;
  }

  get simpleMode(): boolean {
    return this.mode === ProductSelectorMode.SIMPLE;
  }
  get touchMode(): boolean {
    return this.mode === ProductSelectorMode.TOUCH;
  }

  get hasDefaultPrinter(): boolean {
    return Boolean(this.settingsRepository.defaultReceiptPrinter);
  }

  private showError(e: Error, message: string) {
    if (e) {
      console.log(e);
    }
    this.showMessage(message);
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 1000 });
  }
}
