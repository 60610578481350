import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@eceos/common-components';
import { Sale, SaleItem } from '@eceos/domain';
import { SaleItemDetailComponent } from '../../sale-item-detail/sale-item-detail.component';

export interface updateItem {
  sale: Sale,
  saleItem: SaleItem,
  weighingListener: (weighing:boolean) => void
}

@Component({
  selector: 'app-sale-item-update-dialog',
  templateUrl: './sale-item-update-dialog.component.html',
  styleUrls: ['./sale-item-update-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaleItemUpdateDialogComponent implements OnInit {

  _saleItem: SaleItem;

  @ViewChild('saleItemDetail') saleItemDetailRef: SaleItemDetailComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: updateItem,
    private dialogRef: MatDialogRef<SaleItemUpdateDialogComponent>,
    private dialog: MatDialog
  ) {
    this._saleItem = this.data.saleItem.clone();
  }

  ngOnInit(): void {
  }

  removeItem() {
    if (this._saleItem) {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Remover Item',
          content: 'Você realmente deseja remover este item?'
        }
      })
        .afterClosed()
        .subscribe((accept: boolean) => {
          if (accept) {
            this.data.sale.removeItem(this.data.saleItem);
            this.dialogRef.close();
          } else {
            this.saleItemDetailRef.addButtonRequestFocus();
          }
        })
    }
  }

  updateItem() {
    if (this._saleItem) {
      this.data.saleItem.amount = this._saleItem.amount;
      this.data.saleItem.unitaryPrice = this._saleItem.unitaryPrice;
      this.closeDialog();
    }
  }

  hasItemChanged() {
    return this._saleItem.amount != this.data.saleItem.amount ||
      this._saleItem.unitaryPrice != this.data.saleItem.unitaryPrice
  }

  closeDialog() {
    if (this.hasItemChanged()) {
      this.confirmDialog();
    } else {
      this.dialogRef.close();
    }
  }

  confirmDialog() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Descartar Alterações',
        content: 'Suas alterações não foram aplicadas, deseja descartá-las?'
      }
    })
      .afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          this.dialogRef.close()
        }
      })
  }

  weighing(weighing: boolean) {
    this.data.weighingListener(weighing)
  }

}
