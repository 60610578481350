import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import {
  Sale,
  VendorSummary,
  ClientSummary,
  GuestCardSummary,
  VendorsRepository,
  ClientsRepository,
  GuestCardsRepository
} from '@eceos/domain';
import { debounceTime, distinctUntilChanged, switchMap, map, filter } from 'rxjs/operators';
import { isValidCpf, isValidCnpj } from '@brazilian-utils/validators';
import { RequestMetadata, InterceptorConfig } from '@eceos/arch';

@Component({
  selector: 'app-sale-details-data',
  templateUrl: './sale-details-data.component.html',
  styleUrls: ['./sale-details-data.component.scss']
})
export class SaleDetailsDataComponent {
  @Input() sale: Sale;
  @Output() saleChange = new EventEmitter<Sale>();

  @ViewChild('vendorInput') vendorInput: ElementRef & HTMLInputElement;
  @ViewChild('clientInput') clientInput: ElementRef & HTMLInputElement;
  @ViewChild('guestCardInput') guestCardInput: ElementRef & HTMLInputElement;

  @ViewChildren(MatAutocompleteTrigger) acFields: MatAutocompleteTrigger[];

  vendors: VendorSummary[] = [];

  clients: Observable<ClientSummary[]>;

  clientQuery = new Subject<string>();

  guestCards: Observable<GuestCardSummary[]>;

  guestCardQuery = new Subject<string>();

  blockGuestCard = false;

  hasGuestCards = false;

  private defaultRequestMetadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };

  constructor(
    private vendorsRepository: VendorsRepository,
    private clientsRepository: ClientsRepository,
    private guestCardsRepository: GuestCardsRepository,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {
    this.vendorsRepository.list().subscribe(v => (this.vendors = v));
    this.clients = this.clientQuery.asObservable().pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(q => (q.length > 2 ? this.clientsRepository.list(q) : of([])))
    );
    this.guestCards = this.guestCardQuery.asObservable().pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(q => (q.length > 1 ? this.guestCardsRepository.list(q) : of([]))),
      map(list => list.filter(s => !s.currentSale || s.currentSale.id === this.sale.id))
    );
    this.route.queryParams
      .pipe(
        map(params => params['guestCard']),
        filter(v => v),
        switchMap(id => this.guestCardsRepository.findSummary(id))
      )
      .subscribe(guestCard => {
        this.sale.guestCard = guestCard;
        this.blockGuestCard = true;
      });

    this.guestCardsRepository
      .count()
      .subscribe(count => (count > 0 ? (this.hasGuestCards = true) : (this.hasGuestCards = false)));
  }

  get hasVendors(): boolean {
    return this.vendors.length > 0;
  }

  display(data: VendorSummary | ClientSummary | GuestCardSummary): string {
    return data ? data.name : null;
  }

  onVendorSelected(event: MatAutocompleteSelectedEvent): void {
    this.sale.vendor = event.option.value;
    this.saleChange.emit(this.sale);
  }

  onGuestCardSelected(event: MatAutocompleteSelectedEvent): void {
    this.sale.guestCard = event.option.value;
    this.saleChange.emit(this.sale);
  }

  clientKeypress(input: HTMLInputElement) {
    this.clientQuery.next(input.value);
  }

  guestCardKeypress(input: HTMLInputElement) {
    this.guestCardQuery.next(input.value);
  }

  onClientSelected(event: MatAutocompleteSelectedEvent): void {
    this.sale.client = event.option.value;
    this.sale.cnp = this.sale.client ? this.sale.client.cnp : null;
    this.saleChange.emit(this.sale);
  }

  focusOut(input: HTMLInputElement, value: VendorSummary | ClientSummary | GuestCardSummary) {
    if (input.value) {
      input.value = this.display(value);
    } else {
      const v: any = value;
      if (v instanceof GuestCardSummary) {
        this.sale.guestCard = null;
        this.saleChange.emit(this.sale);
      } else if (v instanceof VendorSummary) {
        this.sale.vendor = null;
        this.saleChange.emit(this.sale);
      } else if (v instanceof ClientSummary) {
        this.sale.client = null;
        this.sale.cnp = null;
        this.saleChange.emit(this.sale);
      }
    }
  }

  cnpValidate(input: HTMLInputElement) {
    const cnp = input.value;
    if (cnp.length === 14 && !isValidCpf(cnp)) {
      input.setCustomValidity('O CPF informado é inválido');
    } else if (cnp.length === 18 && !isValidCnpj(cnp)) {
      input.setCustomValidity('O CNPJ informado é  inválido');
    } else {
      input.setCustomValidity('');
    }
  }

  notifyInvalidCnp(input: HTMLInputElement) {
    this.snackBar.dismiss();
    if (input.validationMessage) {
      this.snackBar.open(input.validationMessage, null, {
        duration: 2000
      });
    }
  }

  onCnpChange(input: HTMLInputElement) {
    this.sale.cnp = input.value;
    this.saleChange.emit(this.sale);
  }

  requestFocus() {
    this.closeAllPanels();
    let toFocus: ElementRef & HTMLInputElement = null;
    if (this.hasGuestCards && !this.blockGuestCard) {
      toFocus = this.guestCardInput;
    } else if (this.hasVendors) {
      toFocus = this.vendorInput;
    } else {
      toFocus = this.clientInput;
    }
    if (toFocus) {
      toFocus.nativeElement.focus();
    }
  }

  closeAllPanels() {
    this.acFields.forEach(f => f.closePanel());
  }

  onSearchSubmit(event: KeyboardEvent): void {
    if (!this.sale.guestCard) {
      const search = <HTMLInputElement>event.target;
      const query = search.value.trim();
      if (query.length > 2) {
        this.guestCardsRepository.findByBarcode(query, this.defaultRequestMetadata).subscribe(
          result => {
            this.closeAllPanels();
            this.sale.guestCard = result;
            event.preventDefault();
            setTimeout(() => {
              if (this.hasVendors) {
                this.vendorInput.nativeElement.focus();
              } else {
                this.clientInput.nativeElement.focus();
              }
            }, 100);
          },
          e => {
            search.value = '';
            this.snackBar.open(`Código [${query}] não encontrado`, null, {
              duration: 1000
            });
          }
        );
      }
    }
  }
}
