<div class="line">
  <mat-form-field id="label" class="labelContainer" thin>
    <input matInput thin value="Desconto" readonly tabindex="-1" />
  </mat-form-field>
  <mat-form-field id="percent" class="inputContainer" thin>
    <mat-label>Desconto %</mat-label>
    <input matInput type="number" thin [(ngModel)]="percent" min="0" [max]="maxPercent" [maxValue]="maxPercent" />
    <span matSuffix>%</span>
  </mat-form-field>
  <mat-form-field id="value" class="inputContainer" thin>
    <mat-label>Desconto R$</mat-label>
    <input matInput thin currencyMask [(ngModel)]="value" [max]="maxValue" />
  </mat-form-field>
</div>
