<div class="header">
    <div class="title">
        <button mat-icon-button (click)="closeDialog()" tabindex="-1">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <h4 mat-dialog-title>Atualização de Item</h4>
    </div>
    <div class="delete-item">
        <mat-menu #appMenu="matMenu">
            <button mat-menu-item type="button" *ngIf="_saleItem" (click)="removeItem()">Remover</button>
        </mat-menu>
        <button mat-icon-button type="button" [matMenuTriggerFor]="appMenu" tabindex="0">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
</div>

<section mat-dialog-content>
    <div class="item-name">
        <mat-form-field>
            <mat-label>Nome do Item</mat-label>
            <input matInput readonly [ngModel]="_saleItem.operatable.name" disabled="true" />
        </mat-form-field>
    </div>
    <div class="itemDetails" (keyup.enter)="updateItem()">
        <app-sale-item-detail #saleItemDetail [item]="_saleItem" [inputButtons]="true"
            (weighing)="weighing($event)" [enableHotkeys]="true"></app-sale-item-detail>
    </div>
</section>

<mat-dialog-actions>
    <button mat-raised-button type="button" color="accent" (click)="updateItem()" [disabled]="!_saleItem" tabindex="0">
        Aplicar
    </button>
</mat-dialog-actions>