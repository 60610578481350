import { SgvId, DomainEntity, SgvJson } from '@eceos/arch';
import { HwHub } from './hw-hub';

export class RemoteWeighingMachineSummary implements DomainEntity {
  readonly id = SgvId.gen();
  readonly name = '';
  readonly hub: HwHub = null;

  get weighingMachineNameHub() {
    return `${this.hub.name} - ${this.name}`;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      hub: this.hub.toJson()
    });
  }

  static fromJson(json: any): RemoteWeighingMachineSummary {
    return SgvJson.from.simple(json, RemoteWeighingMachineSummary, {
      hub: HwHub.fromJson(json.hub)
    });
  }
}
