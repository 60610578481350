<mat-sidenav-container>
  <mat-sidenav #sidemenu mode="over">
    <app-menu></app-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="bg">
      <app-suspension-notification>
      </app-suspension-notification>
      <mat-toolbar id="actionbar" color="primary" class="toolbar">
        <button mat-icon-button (click)="sidemenu.open()">
          <mat-icon>menu</mat-icon>
        </button>

        <mat-icon
          routerLink="/"
          class="mat-icon-logo cursor-pointer"
          svgIcon="assets:logo_short_eceos_white"
        >
        </mat-icon>

        <span class="center">
          <ng-content select=".center"></ng-content>
        </span>

        <ng-container actions>
          <button
            mat-icon-button
            *ngIf="showHelp"
            (click)="openHelp()"
            matTooltip="Ajuda"
            aria-label="Ajuda"
          >
            <mat-icon>contact_support</mat-icon>
          </button>
          <ng-content select=".actions"></ng-content>
        </ng-container>
      </mat-toolbar>
      <div class="body" [class.loading]="loading">
        <ng-content></ng-content>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
